import React, { Component } from "react";
import { Link } from "react-router-dom";
import atencao from "../../img/atencao.svg";
import logo from "../../img/logo.png";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextField, Button } from "@mui/material";

import {
  faBuilding,
  faClipboard,
  faClose,
  faCoffee,
  faEnvelope,
  faEye,
  faFileAlt,
  faFileInvoice,
  faFilePdf,
  faHome,
  faIdCard,
  faMapMarkerAlt,
  faMinus,
  faMobileAlt,
  faPen,
  faPhone,
  faSave,
  faSearch,
  faStore,
  faTrash,
  faUser,
  faUserPlus,
  faUserTimes,
} from "@fortawesome/free-solid-svg-icons";
import { StaticDialog, useDialog } from "react-st-modal";
import InputMask from "react-input-mask";
import { apiUrl } from "../../comps/apiUrl";
import { toast } from "react-toastify";
class CadastroClientes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      add: false,
      dados: { telefone: "" },
      items: [],
      currentPage: 1,
      totalItems: 0,
      itemsPerPage: 100,
      edit: {},
      trash: {},
      cpfCnpj: "",
      tpcad: null,
      blockUser: {},
      files: [],
      previews: [],
      f: 2,
      openDropdownId: null, // Guarda o ID do dropdown aberto
    };
    this.dropdownRefs = {}; // Armazena referências para os dropdowns
  }

  componentDidMount() {
    document.title = "Cadastro de clientes";
    this.fetchData(this.state.currentPage);
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  toggleDropdown = (id) => {
    this.setState((prevState) => ({
      openDropdownId: prevState.openDropdownId === id ? null : id, // Fecha se clicar de novo
    }));
  };
  TypeBlock(type) {
    if (type == 0) {
      return <span>Desbloqueado</span>;
    } else if (type == 1) {
      return <span style={{ color: "red" }}>Bloqueado Para Locação</span>;
    } else if (type == 2) {
      return <span style={{ color: "red" }}>Bloqueado Para Serviço</span>;
    } else if (type == 3) {
      return <span>Bloqueio Total</span>;
    } else {
      return <span>Desbloqueado</span>;
    }
  }
  handleClickOutside = (event) => {
    // Verifica se o clique foi fora do dropdown aberto
    if (
      this.state.openDropdownId !== null &&
      this.dropdownRefs[this.state.openDropdownId] &&
      !this.dropdownRefs[this.state.openDropdownId].contains(event.target)
    ) {
      this.setState({ openDropdownId: null });
    }
  };

  handleRemoveImage = (index) => {
    const { files, previews } = this.state;

    // Remover o arquivo e a pré-visualização do estado
    const updatedFiles = files.filter((_, i) => i !== index);
    const updatedPreviews = previews.filter((_, i) => i !== index);

    this.setState({ files: updatedFiles, previews: updatedPreviews });
  };

  handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    let validFiles = [];
    let previews = [];

    files.forEach((file) => {
      if (file.type.startsWith("image/")) {
        validFiles.push(file);
        previews.push({ src: URL.createObjectURL(file), type: "image" });
      } else if (file.type === "application/pdf") {
        validFiles.push(file);
        previews.push({ type: "pdf" }); // Placeholder para PDFs
      } else {
        toast.error("Apenas imagens e PDFs são permitidos!");
      }
    });

    // Concatenar os novos arquivos com os existentes
    this.setState((prevState) => ({
      files: [...prevState.files, ...validFiles], // Guarda todos os arquivos
      previews: [...prevState.previews, ...previews], // Guarda pré-visualizações (imagens reais e placeholder para PDF)
    }));
  };

  cnpj(v) {
    v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
    v = v.replace(/^(\d{2})(\d)/, "$1.$2"); //Coloca ponto entre o segundo e o terceiro dígitos
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3"); //Coloca ponto entre o quinto e o sexto dígitos
    v = v.replace(/\.(\d{3})(\d)/, ".$1/$2"); //Coloca uma barra entre o oitavo e o nono dígitos
    v = v.replace(/(\d{4})(\d)/, "$1-$2"); //Coloca um hífen depois do bloco de quatro dígitos
    return v;
  }

  cpf(v) {
    v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
    v = v.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
    //de novo (para o segundo bloco de números)
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2"); //Coloca um hífen entre o terceiro e o quarto dígitos
    return v;
  }

  fetchData(page) {
    fetch(`${apiUrl}/clientes?page=${page}&limit=${this.state.itemsPerPage}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      response.json().then((data) => {
        const totalItems = data.totalItems; // Simulado, substitua pelo valor real do backend.
        const totalPages = Math.ceil(totalItems / this.state.itemsPerPage);

        this.setState({
          items: data.items,
          currentPage: page,
          totalPages,
          loader: false,
        });
      });
    });
  }

  handlePageClick = (page) => {
    this.fetchData(page);
  };
  handleNextPage = () => {
    this.fetchData(this.state.currentPage + 1);
  };

  handlePreviousPage = () => {
    if (this.state.currentPage > 1) {
      this.fetchData(this.state.currentPage - 1);
    }
  };

  renderPagination() {
    const { currentPage, totalPages } = this.state;

    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => this.handlePageClick(i)}
          style={{
            backgroundColor: i === currentPage ? "#007bff" : "#fff",
            color: i === currentPage ? "#fff" : "#000",
            border: "1px solid #ddd",
            cursor: "pointer",
          }}
        >
          {i}
        </button>
      );
    }

    return (
      <div>
        <button
          className="btn btn-ligth"
          onClick={() => this.handlePageClick(currentPage - 1)}
          disabled={currentPage === 1}
          style={{
            cursor: currentPage === 1 ? "not-allowed" : "pointer",
          }}
        >
          &lt;
        </button>

        {pages}

        <button
          onClick={() => this.handlePageClick(currentPage + 1)}
          className="btn btn-ligth"
          disabled={currentPage === totalPages}
          style={{
            cursor: currentPage === totalPages ? "not-allowed" : "pointer",
          }}
        >
          &gt;
        </button>
      </div>
    );
  }

  handleChange = (event) => {
    const { name, value } = event.target;

    // Atualizando o estado dentro do objeto `dados`
    this.setState((prevState) => ({
      dados: {
        ...prevState.dados, // Mantém os outros campos do objeto `dados`
        [name]: value, // Atualiza apenas o campo correspondente
      },
    }));
  };

  handleEditChange = (event) => {
    const { name, value } = event.target;

    // Atualizando o estado dentro do objeto `dados`
    this.setState((prevState) => ({
      edit: {
        ...prevState.edit, // Mantém os outros campos do objeto `dados`
        [name]: value, // Atualiza apenas o campo correspondente
      },
    }));
  };

  handleEdit = (event) => {
    const { id, value } = event.target;

    // Atualizando o estado dentro do objeto `dados`
    this.setState((prevState) => ({
      edit: {
        ...prevState.edit, // Mantém os outros campos do objeto `dados`
        [id]: value, // Atualiza apenas o campo correspondente
      },
    }));
  };
  getPhoneMask = () => {
    const { telefone } = this.state.dados;
    return telefone.replace(/\D/g, "").length > 10
      ? "(99) 9 9999-9999" // Com nono dígito
      : "(99) 9999-9999"; // Sem nono dígito
  };

  render() {
    if (this.state.loader) {
      return (
        <div class="area-loader">
          <div class="loader"></div>
        </div>
      );
    }

    return (
      <>
        <div class="content">
          <div class="card">
            <div class="card-header d-flex align-items-center justify-content-between">
              <strong>Cadastro e Lista de Clientes</strong>
              <button
                class="btn btn-primary"
                onClick={() => {
                  this.setState({ add: true });
                }}
              >
                {" "}
                <FontAwesomeIcon icon={faUserPlus} /> Cadastrar cliente
              </button>
            </div>
            <div class="card-body">
              <div class="mb-3 d-flex justify-content-center">
                <div className="w-50">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      var itens = e.target;

                      if (itens[0].value == "") {
                        toast.warning("Selecione um filtro");
                        return;
                      }
                      if (itens[1].value.trim().length == 0) {
                        toast.warning("Preencha o campo de pesquisa");
                        return;
                      }

                      fetch(`${apiUrl}/buscar-cliente`, {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                          authorization: localStorage.getItem("token"),
                        },
                        body: JSON.stringify({
                          filter: itens[0].value,
                          search: itens[1].value,
                        }),
                      }).then((response) => {
                        itens[1].classList.remove("load");
                        if (response.status == 200) {
                          response.json().then((data) => {
                            const totalItems = data.totalItems; // Simulado, substitua pelo valor real do backend.
                            const totalPages = Math.ceil(
                              totalItems / this.state.itemsPerPage
                            );

                            this.setState({
                              items: data.items,
                              currentPage: 1,
                              totalPages,
                              loader: false,
                            });
                          });
                        }
                      });
                    }}
                  >
                    <div class="input-group">
                      <select
                        class="form-select"
                        value={this.state.f || ""}
                        onChange={(e) => {
                          this.setState({ f: e.target.value });
                        }}
                      >
                        <option>Selecione:</option>
                        <option value="1">Cod. cliente</option>
                        <option value="2">Nome do Cliente</option>

                        <option value="3">Tipo de Cliente</option>
                        <option value="4">CNPJ/CPF</option>
                        <option value="5">Telefone</option>
                        <option value="6">Cidade</option>
                      </select>
                      {this.state.f == "3" ? (
                        <>
                          <select
                            class="form-select"
                            onChange={(e) => {
                              this.setState({ search: e.target.value });
                            }}
                          >
                            <option value="">Selecione:</option>
                            <option value="1">Locação</option>
                            <option value="2">Venda</option>
                          </select>
                        </>
                      ) : (
                        <>
                          <input
                            type="text"
                            class="form-control "
                            id="searchInput"
                            onChange={(e) => {
                              this.setState({ search: e.target.value });
                            }}
                            placeholder="Pesquisar..."
                          />
                        </>
                      )}

                      <button type="submit" class="btn btn-primary">
                        <FontAwesomeIcon icon={faSearch} />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              {this.state.items.length > 0 ? (
                <>
                  <table class="table table-striped table-bordered arvore-l">
                    <thead class="thead-dark">
                      <tr>
                        <th>Cod. cliente</th>
                        <th>Nome do Cliente</th>
                        <th>Status</th>
                        <th>Tipo de Cliente</th>
                        <th>CNPJ/CPF</th>
                        <th>Telefone</th>
                        <th>Cidade</th>
                        <th>Opções</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.items.map((item, index) => (
                        <>
                          <tr>
                            <td>{item.id}</td>
                            <td>{item.nome}</td>
                            <td>{this.TypeBlock(item.status)}</td>
                            <td>
                              {item.tipo_cliente != undefined
                                ? item.tipo_cliente == "1"
                                  ? "Locação"
                                  : "Venda"
                                : "---"}
                            </td>
                            <td>{item.cnpj_cpf}</td>
                            <td>{item.telefone}</td>
                            <td>{item.cidade}</td>

                            <td style={{ position: "relative" }}>
                              <div
                                key={item.id}
                                ref={(el) => (this.dropdownRefs[item.id] = el)} // Salva a referência do dropdown
                                style={{
                                  position: "relative",
                                  display: "inline-block",
                                }}
                              >
                                <button
                                  className="btn btn-primary"
                                  onClick={() => this.toggleDropdown(item.id)}
                                  style={{ padding: "10px", cursor: "pointer" }}
                                >
                                  Selecione
                                </button>

                                {this.state.openDropdownId === item.id && (
                                  <div
                                    className="dropdown-menu-rb"
                                    style={{ marginLeft: -135 }}
                                  >
                                    <div className="d-flex justify-content-center align-items-center">
                                      <Link
                                        style={{ marginRight: "10px" }}
                                        to={`/cadastro-clientes/view/${item.id}`}
                                        className="btn btn-success"
                                      >
                                        <FontAwesomeIcon icon={faEye} />
                                      </Link>

                                      <button
                                        className="btn btn-primary"
                                        style={{ marginRight: "10px" }}
                                        onClick={(e) => {
                                          this.setState({
                                            edit: item,
                                            tpcad:
                                              item.cnpj_cpf == ""
                                                ? null
                                                : item.cnpj_cpf.length == 14
                                                ? "cpf"
                                                : "cnpj",
                                          });
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faPen} />
                                      </button>

                                      <button
                                        className="btn btn-danger"
                                        style={{ marginRight: "10px" }}
                                        onClick={() => {
                                          this.setState({ trash: item });
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faTrash} />
                                      </button>
                                      <button
                                        className="btn btn-danger"
                                        style={{ marginRight: "10px" }}
                                        onClick={() => {
                                          this.setState({
                                            blockUser: item,
                                          });
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faUserTimes} />
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </>
              ) : (
                <>Não há resultados</>
              )}
            </div>

            <div
              className="card-footer"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {this.renderPagination()}
            </div>
          </div>
        </div>

        <StaticDialog
          isOpen={Object.keys(this.state.trash).length > 0}
          title="Tem certeza de que deseja continuar?"
          onAfterClose={(result) => {
            this.setState({ trash: {} });
          }}
        >
          <div style={{ margin: 10 }}>
            Se você confirmar a exclusão, não será possível recuperar os dados
            posteriormente.
          </div>

          <div className="modal-fotter">
            <button
              className="btn btn-danger"
              onClick={(e) => {
                e.target.classList.add("load");
                var id = this.state.trash.id;
                fetch(`${apiUrl}/deletar-cliente`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    // outras headers, se necessário
                  },
                  body: JSON.stringify({ id }), // corpo da requisição em formato JSON
                })
                  .then((response) => {
                    e.target.classList.remove("load");
                    if (response.ok) {
                      this.setState((prevState) => ({
                        items: prevState.items.filter((item) => item.id !== id),
                        trash: {},
                      }));

                      toast.success("Cliente excluido com sucesso");
                    }
                  })
                  .catch((error) => {
                    toast.error("Erro ao enviar dados:", error);
                  });
              }}
            >
              <FontAwesomeIcon icon={faTrash} /> Excluir
            </button>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={this.state.add}
          title="Cadastrar Cliente"
          onAfterClose={(result) => {
            this.setState({
              add: false,
              tpcad: null,
              files: [],
              previews: [],
            });
          }}
        >
          {this.state.tpcad == null ? (
            <div
              style={{ display: "flex", justifyContent: "center", margin: 10 }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 100,
                  height: 100,
                  cursor: "pointer",
                  border: "1px solid black",
                }}
                onClick={() => {
                  this.setState({ tpcad: "cpf" });
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <FontAwesomeIcon icon={faUser} />
                  Cadastro de Pessoa Física
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 100,
                  height: 100,
                  cursor: "pointer",
                  marginLeft: 10,
                  border: "1px solid black",
                }}
                onClick={() => {
                  this.setState({ tpcad: "cnpj" });
                }}
              >
                {" "}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <FontAwesomeIcon icon={faStore} />
                  Cadatro de Empresa
                </div>
              </div>
            </div>
          ) : (
            <>
              {this.state.tpcad == "cpf" ? (
                <>
                  <div className="container">
                    <form
                      id="cadastroForm"
                      onSubmit={(e) => {
                        var dados = this.state.dados;
                        e.preventDefault();
                      }}
                    >
                      <div className="mt-3">
                        <label className="mb-2">Tipo de Cliente</label>
                        <select
                          className="form-control"
                          name="tipo_cliente"
                          onChange={this.handleChange}
                        >
                          <option value="">Selecione</option>
                          <option value="1">Locação</option>
                          <option value="2">Venda</option>
                        </select>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <TextField
                            label="Nome"
                            name="nome"
                            onChange={this.handleChange}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>

                        <div className="col-md-6">
                          <InputMask
                            mask="99/99/9999"
                            name="data_nascimento"
                            value={this.state.dados.data_nascimento || ""}
                            onChange={this.handleChange}
                          >
                            {() => (
                              <TextField
                                label="Data de Nascimento"
                                name="data_nascimento"
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  style: {
                                    height: 45,
                                    padding: "0 5px",
                                  },
                                }}
                              />
                            )}
                          </InputMask>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <InputMask
                            name="cnpj_cpf"
                            onChange={this.handleChange}
                            mask="999.999.999-99" // Máscara de telefone
                          >
                            {(inputProps) => (
                              <TextField
                                {...inputProps}
                                label="Cpf"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  style: {
                                    height: 45,
                                    padding: "0 5px",
                                  },
                                }}
                                margin="normal"
                              />
                            )}
                          </InputMask>
                        </div>
                        <div className="col-md-6">
                          <InputMask
                            name="rg"
                            onChange={this.handleChange}
                            mask="99.999.999-9" // Máscara de telefone
                          >
                            {(inputProps) => (
                              <TextField
                                {...inputProps}
                                label="Rg"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  style: {
                                    height: 45,
                                    padding: "0 5px",
                                  },
                                }}
                                margin="normal"
                              />
                            )}
                          </InputMask>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <TextField
                            label="Nome do Pai"
                            name="npai"
                            value={this.state.npai}
                            onChange={(e) => this.handleChange(e)}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                        <div className="col-md-6">
                          <TextField
                            label="Nome da Mae"
                            name="nmae"
                            value={this.state.nmae}
                            onChange={(e) => this.handleChange(e)}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <TextField
                            label="telefone"
                            name="telefone"
                            value={this.state.telefone}
                            onChange={(e) => this.handleChange(e)}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                        <div className="col-md-6">
                          <InputMask
                            name="celular"
                            onChange={this.handleChange}
                            mask="(99) 9 9999-9999" // Máscara de telefone
                          >
                            {(inputProps) => (
                              <TextField
                                {...inputProps}
                                label="WhatsApp"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  style: {
                                    height: 45,
                                    padding: "0 5px",
                                  },
                                }}
                                margin="normal"
                              />
                            )}
                          </InputMask>
                        </div>
                      </div>
                      <div>
                        <TextField
                          label="E-mail"
                          name="email"
                          onChange={this.handleChange}
                          variant="outlined" // Pode ser "filled" ou "standard"
                          fullWidth // O campo ocupa toda a largura disponível
                          margin="normal"
                          InputProps={{
                            style: {
                              height: 45,
                              padding: "0 5px",
                            },
                          }}
                        />
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <InputMask
                            name="cep"
                            onChange={async (e) => {
                              const cep = e.target.value.replace(/\D/g, ""); // Remove caracteres não numéricos
                              this.setState({ cep });

                              if (cep.length === 8) {
                                fetch(`https://viacep.com.br/ws/${cep}/json/`)
                                  .then((response) => response.json())
                                  .then((data) => {
                                    if (data.error == "true") {
                                      toast.warning("CEP inválido");
                                    } else {
                                      this.setState((prevState) => ({
                                        dados: {
                                          ...prevState.dados, // Mantém os outros campos do objeto `dados`
                                          ["endereco"]: data.logradouro, // Atualiza apenas o campo correspondente
                                          ["bairro"]: data.bairro,
                                          ["cidade"]: data.localidade,
                                          ["estado"]: data.estado,
                                        },
                                      }));
                                    }
                                  })
                                  .catch((error) => {
                                    toast.warning("CEP inválido");
                                  });
                              }
                            }}
                            mask="99.999-999" // Máscara de telefone
                          >
                            {(inputProps) => (
                              <TextField
                                {...inputProps}
                                label="CEP:"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  style: {
                                    height: 45,
                                    padding: "0 5px",
                                  },
                                }}
                                margin="normal"
                              />
                            )}
                          </InputMask>
                        </div>
                        <div className="col-md-6">
                          <TextField
                            label="Endereço"
                            name="endereco"
                            value={this.state.dados.endereco || ""}
                            onChange={this.handleChange}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4">
                          <TextField
                            label="Número"
                            name="numero"
                            onChange={this.handleChange}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                        <div className="col-md-4">
                          <TextField
                            label="Complemento"
                            name="complemento"
                            onChange={this.handleChange}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                        <div className="col-md-4">
                          <TextField
                            label="Bairro"
                            name="bairro"
                            onChange={this.handleChange}
                            value={this.state.dados.bairro || ""}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-6">
                          <TextField
                            label="Cidade"
                            name="cidade"
                            value={this.state.dados.cidade || ""}
                            onChange={this.handleChange}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                        <div className="col-md-6">
                          <TextField
                            label="Estado"
                            name="estado"
                            value={this.state.dados.estado || ""}
                            onChange={this.handleChange}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                        <div className="mt-1">
                          <label className="mb-2">Documento:</label>
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",

                              flexWrap: "wrap",
                            }}
                          >
                            {this.state.previews.map((item, index) => (
                              <>
                                {item.type === "image" ? (
                                  <>
                                    {" "}
                                    <div
                                      key={index}
                                      style={{
                                        position: "relative",
                                        display: "inline-block",
                                      }}
                                    >
                                      <img
                                        src={item.src}
                                        alt={`preview-${index}`}
                                        style={{
                                          width: "100px",
                                          height: "100px",
                                          objectFit: "cover",
                                          border: "1px solid #ccc",
                                          borderRadius: "5px",
                                        }}
                                      />
                                      <button
                                        onClick={() =>
                                          this.handleRemoveImage(index)
                                        }
                                        style={{
                                          position: "absolute",
                                          top: "5px",
                                          right: "5px",
                                          background: "red",
                                          color: "white",
                                          border: "none",
                                          borderRadius: "50%",
                                          width: "20px",
                                          height: "20px",
                                          cursor: "pointer",
                                          fontSize: "14px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faClose}
                                          color="#fff"
                                        />
                                      </button>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      key={index}
                                      style={{
                                        position: "relative",
                                        display: "inline-block",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "100px",
                                          height: "100px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          border: "1px solid #ccc",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        {" "}
                                        <FontAwesomeIcon icon={faFilePdf} />
                                      </div>

                                      <button
                                        onClick={() =>
                                          this.handleRemoveImage(index)
                                        }
                                        style={{
                                          position: "absolute",
                                          top: "5px",
                                          right: "5px",
                                          background: "red",
                                          color: "white",
                                          border: "none",
                                          borderRadius: "50%",
                                          width: "20px",
                                          height: "20px",
                                          cursor: "pointer",
                                          fontSize: "14px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faClose}
                                          color="#fff"
                                        />
                                      </button>
                                    </div>
                                  </>
                                )}
                              </>
                            ))}
                          </div>
                          <div
                            className="custom-upl"
                            style={{
                              marginTop:
                                this.state.previews.length > 0 ? "20px" : "0",
                            }}
                          >
                            <input
                              type="file"
                              multiple
                              accept="image/*,application/pdf" // Isso já ajuda a restringir para imagens no navegador
                              onChange={this.handleFileChange}
                            />
                            <button className="btn btn-primary">
                              <FontAwesomeIcon icon={faFileAlt} /> Anexar
                              Documento
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </>
              ) : (
                <>
                  <div className="container">
                    <form
                      id="cadastroForm"
                      onSubmit={(e) => {
                        var dados = this.state.dados;
                        e.preventDefault();
                      }}
                    >
                      <div className="mt-3">
                        <label className="mb-2">Tipo de Cliente</label>
                        <select
                          className="form-control"
                          name="tipo_cliente"
                          onChange={this.handleChange}
                        >
                          <option value="">Selecione</option>
                          <option value="1">Locação</option>
                          <option value="2">Venda</option>
                        </select>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <TextField
                            label="Nome"
                            name="nome"
                            onChange={this.handleChange}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                        <div className="col-md-6">
                          <TextField
                            label="Nome Fantasia"
                            name="nome_fantasia"
                            onChange={this.handleChange}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <InputMask
                            name="cnpj_cpf"
                            onChange={this.handleChange}
                            mask="99.999.999/9999-99" // Máscara de telefone
                          >
                            {(inputProps) => (
                              <TextField
                                {...inputProps}
                                label="Cnpj"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  style: {
                                    height: 45,
                                    padding: "0 5px",
                                  },
                                }}
                                margin="normal"
                              />
                            )}
                          </InputMask>
                        </div>
                        <div className="col-md-6">
                          <InputMask
                            name="inscricao_estadual"
                            onChange={this.handleChange}
                            mask="99.999.999.999" // Máscara de telefone
                          >
                            {(inputProps) => (
                              <TextField
                                {...inputProps}
                                label="Inscrião Estadual"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  style: {
                                    height: 45,
                                    padding: "0 5px",
                                  },
                                }}
                                margin="normal"
                              />
                            )}
                          </InputMask>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <TextField
                            label="telefone"
                            name="telefone"
                            value={this.state.telefone}
                            onChange={(e) => this.handleChange(e)}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                        <div className="col-md-6">
                          <InputMask
                            name="celular"
                            onChange={this.handleChange}
                            mask="(99) 9 9999-9999" // Máscara de telefone
                          >
                            {(inputProps) => (
                              <TextField
                                {...inputProps}
                                label="WhatsApp"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  style: {
                                    height: 45,
                                    padding: "0 5px",
                                  },
                                }}
                                margin="normal"
                              />
                            )}
                          </InputMask>
                        </div>
                      </div>
                      <div>
                        <TextField
                          label="E-mail"
                          name="email"
                          onChange={this.handleChange}
                          variant="outlined" // Pode ser "filled" ou "standard"
                          fullWidth // O campo ocupa toda a largura disponível
                          margin="normal"
                          InputProps={{
                            style: {
                              height: 45,
                              padding: "0 5px",
                            },
                          }}
                        />
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <InputMask
                            name="cep"
                            onChange={async (e) => {
                              const cep = e.target.value.replace(/\D/g, ""); // Remove caracteres não numéricos
                              this.setState({ cep });

                              if (cep.length === 8) {
                                fetch(`https://viacep.com.br/ws/${cep}/json/`)
                                  .then((response) => response.json())
                                  .then((data) => {
                                    if (data.error == "true") {
                                      toast.warning("CEP inválido");
                                    } else {
                                      this.setState((prevState) => ({
                                        dados: {
                                          ...prevState.dados, // Mantém os outros campos do objeto `dados`
                                          ["endereco"]: data.logradouro, // Atualiza apenas o campo correspondente
                                          ["bairro"]: data.bairro,
                                          ["cidade"]: data.localidade,
                                          ["uf"]: data.uf,
                                        },
                                      }));
                                    }
                                  })
                                  .catch((error) => {
                                    toast.warning("CEP inválido");
                                  });
                              }
                            }}
                            mask="99.999-999" // Máscara de telefone
                          >
                            {(inputProps) => (
                              <TextField
                                {...inputProps}
                                label="CEP:"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  style: {
                                    height: 45,
                                    padding: "0 5px",
                                  },
                                }}
                                margin="normal"
                              />
                            )}
                          </InputMask>
                        </div>
                        <div className="col-md-6">
                          <TextField
                            label="Endereço"
                            name="endereco"
                            value={this.state.dados.endereco || ""}
                            onChange={this.handleChange}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4">
                          <TextField
                            label="Número"
                            name="numero"
                            onChange={this.handleChange}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                        <div className="col-md-4">
                          <TextField
                            label="Complemento"
                            name="complemento"
                            onChange={this.handleChange}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                        <div className="col-md-4">
                          <TextField
                            label="Bairro"
                            name="bairro"
                            onChange={this.handleChange}
                            value={this.state.dados.bairro || ""}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-6">
                          <TextField
                            label="Cidade"
                            name="cidade"
                            value={this.state.dados.cidade || ""}
                            onChange={this.handleChange}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                        <div className="col-md-6">
                          <TextField
                            label="Estado"
                            name="estado"
                            value={this.state.dados.estado || ""}
                            onChange={this.handleChange}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                        <div className="mt-1">
                          <label className="mb-2">Documento:</label>
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",

                              flexWrap: "wrap",
                            }}
                          >
                            {this.state.previews.map((item, index) => (
                              <>
                                {item.type === "image" ? (
                                  <>
                                    {" "}
                                    <div
                                      key={index}
                                      style={{
                                        position: "relative",
                                        display: "inline-block",
                                      }}
                                    >
                                      <img
                                        src={item.src}
                                        alt={`preview-${index}`}
                                        style={{
                                          width: "100px",
                                          height: "100px",
                                          objectFit: "cover",
                                          border: "1px solid #ccc",
                                          borderRadius: "5px",
                                        }}
                                      />
                                      <button
                                        onClick={() =>
                                          this.handleRemoveImage(index)
                                        }
                                        style={{
                                          position: "absolute",
                                          top: "5px",
                                          right: "5px",
                                          background: "red",
                                          color: "white",
                                          border: "none",
                                          borderRadius: "50%",
                                          width: "20px",
                                          height: "20px",
                                          cursor: "pointer",
                                          fontSize: "14px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faClose}
                                          color="#fff"
                                        />
                                      </button>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      key={index}
                                      style={{
                                        position: "relative",
                                        display: "inline-block",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "100px",
                                          height: "100px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          border: "1px solid #ccc",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        {" "}
                                        <FontAwesomeIcon icon={faFilePdf} />
                                      </div>

                                      <button
                                        onClick={() =>
                                          this.handleRemoveImage(index)
                                        }
                                        style={{
                                          position: "absolute",
                                          top: "5px",
                                          right: "5px",
                                          background: "red",
                                          color: "white",
                                          border: "none",
                                          borderRadius: "50%",
                                          width: "20px",
                                          height: "20px",
                                          cursor: "pointer",
                                          fontSize: "14px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faClose}
                                          color="#fff"
                                        />
                                      </button>
                                    </div>
                                  </>
                                )}
                              </>
                            ))}
                          </div>
                          <div
                            className="custom-upl"
                            style={{
                              marginTop:
                                this.state.previews.length > 0 ? "20px" : "0",
                            }}
                          >
                            <input
                              type="file"
                              multiple
                              accept="image/*,application/pdf" // Isso já ajuda a restringir para imagens no navegador
                              onChange={this.handleFileChange}
                            />
                            <button className="btn btn-primary">
                              <FontAwesomeIcon icon={faFileAlt} /> Anexar
                              Documento
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </>
              )}
            </>
          )}

          <div className="modal-fotter">
            <button
              className="btn btn-primary"
              onClick={(e) => {
                var dados = this.state.dados;

                if (this.state.tpcad == "cpf") {
                  if (dados.data_nascimento == undefined) {
                    toast.warn("Selecione uma data de nascimento");
                    return;
                  }
                }

                const formData = new FormData();

                // Adiciona os dados do formulário ao FormData
                formData.append("nome", dados.nome);
                if (this.state.tpcad == "cpf") {
                  formData.append(
                    "data_nascimento",
                    this.state.dados.data_nascimento
                  );
                  formData.append("rg", this.state.dados.rg);
                  formData.append("npai", this.state.dados.npai);
                  formData.append("nmae", this.state.dados.nmae);
                  formData.append("complemento", this.state.dados.complemento);
                } else {
                  formData.append(
                    "inscricao_estadual",
                    this.state.dados.inscricao_estadual
                  );
                }
                formData.append("cnpj_cpf", dados.cnpj_cpf);
                formData.append("email", dados.email);
                formData.append("telefone", dados.telefone);
                formData.append("celular", dados.celular);
                formData.append("cep", dados.cep);
                formData.append("endereco", dados.endereco);
                formData.append("numero", dados.numero);
                formData.append("bairro", dados.bairro);
                formData.append("cidade", dados.cidade);
                formData.append("estado", dados.estado);

                // Verifique se há uma imagem e adicione ao FormData

                this.state.files.forEach((file, index) => {
                  formData.append(`images`, file); // O backend deve suportar array de arquivos
                });

                fetch(
                  `${apiUrl}/cadastrar-cliente${
                    this.state.files.length > 0 ? "/files" : ""
                  }`,
                  {
                    method: "POST",

                    body: formData,
                  }
                )
                  .then((response) => {
                    if (response.status == 200) {
                      response.json().then((data) => {
                        this.setState((prevState) => ({
                          items: [
                            {
                              id: data.id,
                              nome: dados.nome,
                              data_nascimento: dados.data_nascimento,
                              rg: dados.rg,
                              npai: dados.npai,
                              nmae: dados.nmae,
                              cnpj_cpf: dados.cnpj_cpf,
                              email: dados.email,
                              telefone: dados.telefone,
                              celular: dados.celular,
                              cep: dados.cep,
                              endereco: dados.endereco,
                              numero: dados.numero,
                              bairro: dados.bairro,
                              cidade: dados.cidade,
                              estado: dados.estado,
                            },
                            ...prevState.items,
                          ],
                          add: false,
                          cpfCnpj: "",
                        }));

                        toast.success("Cliente adicionado");
                      });
                    }
                  })
                  .catch((err) => {
                    toast.error("Falha na comunicação com o servidor!");
                  });
              }}
            >
              <FontAwesomeIcon icon={faSave} /> Salvar
            </button>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={Object.keys(this.state.edit).length > 0}
          title="Editar Cliente"
          onAfterClose={(result) => {
            this.setState({ edit: {} });
          }}
        >
          {this.state.tpcad == null ? (
            <div
              style={{ display: "flex", justifyContent: "center", margin: 10 }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 100,
                  height: 100,
                  cursor: "pointer",
                  border: "1px solid black",
                }}
                onClick={() => {
                  this.setState({ tpcad: "cpf" });
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <FontAwesomeIcon icon={faUser} />
                  Cadastro de Pessoa Física
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 100,
                  height: 100,
                  cursor: "pointer",
                  marginLeft: 10,
                  border: "1px solid black",
                }}
                onClick={() => {
                  this.setState({ tpcad: "cnpj" });
                }}
              >
                {" "}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <FontAwesomeIcon icon={faStore} />
                  Cadatro de Empresa
                </div>
              </div>
            </div>
          ) : (
            <>
              {this.state.tpcad == "cpf" ? (
                <>
                  <div className="container">
                    <form
                      id=""
                      onSubmit={(e) => {
                        var dados = this.state.dados;
                        e.preventDefault();
                      }}
                    >
                      <div className="mt-3">
                        <label className="mb-2">Tipo de Cliente</label>
                        <select
                          className="form-control"
                          name="tipo_cliente"
                          onChange={this.handleEditChange}
                          value={this.state.edit.tipo_cliente || ""}
                        >
                          <option value="">Selecione</option>
                          <option value="1">Locação</option>
                          <option value="2">Venda</option>
                        </select>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <TextField
                            label="Nome"
                            name="nome"
                            onChange={this.handleEditChange}
                            value={this.state.edit.nome || ""}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>

                        <div className="col-md-6">
                          <InputMask
                            mask="99/99/9999"
                            name="data_nascimento"
                            value={this.state.edit.data_nascimento || ""}
                            onChange={this.handleEditChange}
                          >
                            {() => (
                              <TextField
                                label="Data de Nascimento"
                                name="data_nascimento"
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  style: {
                                    height: 45,
                                    padding: "0 5px",
                                  },
                                }}
                              />
                            )}
                          </InputMask>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <InputMask
                            name="cnpj_cpf"
                            onChange={this.handleEditChange}
                            value={this.state.edit.cnpj_cpf || ""}
                            mask="999.999.999-99" // Máscara de telefone
                          >
                            {(inputProps) => (
                              <TextField
                                {...inputProps}
                                label="Cpf"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  style: {
                                    height: 45,
                                    padding: "0 5px",
                                  },
                                }}
                                margin="normal"
                              />
                            )}
                          </InputMask>
                        </div>
                        <div className="col-md-6">
                          <InputMask
                            name="rg"
                            onChange={this.handleEditChange}
                            value={this.state.edit.rg || ""}
                            mask="99.999.999-9" // Máscara de telefone
                          >
                            {(inputProps) => (
                              <TextField
                                {...inputProps}
                                label="Rg"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  style: {
                                    height: 45,
                                    padding: "0 5px",
                                  },
                                }}
                                margin="normal"
                              />
                            )}
                          </InputMask>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <TextField
                            label="Nome do Pai"
                            name="npai"
                            value={this.state.edit.npai}
                            onChange={(e) => this.handleEditChange(e)}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                        <div className="col-md-6">
                          <TextField
                            label="Nome da Mae"
                            name="nmae"
                            value={this.state.edit.nmae}
                            onChange={(e) => this.handleEditChange(e)}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <TextField
                            label="telefone"
                            name="telefone"
                            onChange={this.handleEditChange}
                            value={this.state.edit.telefone || ""}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>

                        <div className="col-md-6">
                          <InputMask
                            name="celular"
                            onChange={this.handleEditChange}
                            value={this.state.edit.celular || ""}
                            mask="(99) 9 9999-9999" // Máscara de telefone
                          >
                            {(inputProps) => (
                              <TextField
                                {...inputProps}
                                label="WhatsApp"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  style: {
                                    height: 45,
                                    padding: "0 5px",
                                  },
                                }}
                                margin="normal"
                              />
                            )}
                          </InputMask>
                        </div>
                      </div>
                      <div>
                        <TextField
                          label="E-mail"
                          name="email"
                          onChange={this.handleEditChange}
                          value={this.state.edit.email || ""}
                          variant="outlined" // Pode ser "filled" ou "standard"
                          fullWidth // O campo ocupa toda a largura disponível
                          margin="normal"
                          InputProps={{
                            style: {
                              height: 45,
                              padding: "0 5px",
                            },
                          }}
                        />
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <InputMask
                            name="cep"
                            value={this.state.edit.cep || ""}
                            onChange={async (e) => {
                              const cep = e.target.value.replace(/\D/g, ""); // Remove caracteres não numéricos

                              this.setState((prevState) => ({
                                edit: {
                                  ...prevState.edit, // Mantém os outros campos do objeto `dados`

                                  ["cep"]: cep,
                                },
                              }));

                              if (cep.length === 8) {
                                fetch(`https://viacep.com.br/ws/${cep}/json/`)
                                  .then((response) => response.json())
                                  .then((data) => {
                                    if (data.error == "true") {
                                      toast.warning("CEP inválido");
                                    } else {
                                      this.setState((prevState) => ({
                                        edit: {
                                          ...prevState.edit, // Mantém os outros campos do objeto `dados`

                                          ["endereco"]: data.logradouro, // Atualiza apenas o campo correspondente
                                          ["bairro"]: data.bairro,
                                          ["cidade"]: data.localidade,
                                          ["estado"]: data.estado,
                                        },
                                      }));
                                    }
                                  })
                                  .catch((error) => {
                                    toast.warning("CEP inválido");
                                  });
                              }
                            }}
                            mask="99.999-999" // Máscara de telefone
                          >
                            {(inputProps) => (
                              <TextField
                                {...inputProps}
                                label="CEP:"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  style: {
                                    height: 45,
                                    padding: "0 5px",
                                  },
                                }}
                                margin="normal"
                              />
                            )}
                          </InputMask>
                        </div>
                        <div className="col-md-6">
                          <TextField
                            label="Endereço"
                            name="endereco"
                            onChange={this.handleEditChange}
                            value={this.state.edit.endereco || ""}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <TextField
                            label="Número"
                            name="numero"
                            onChange={this.handleEditChange}
                            value={this.state.edit.numero || ""}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                        <div className="col-md-4">
                          <TextField
                            label="Complemento"
                            name="complemento"
                            onChange={this.handleEditChange}
                            value={this.state.edit.complemento || ""}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                        <div className="col-md-4">
                          <TextField
                            label="Bairro"
                            name="bairro"
                            onChange={this.handleEditChange}
                            value={this.state.edit.bairro || ""}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-6">
                          <TextField
                            label="Cidade"
                            name="cidade"
                            onChange={this.handleEditChange}
                            value={this.state.edit.cidade || ""}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                        <div className="col-md-6">
                          <TextField
                            label="Estado"
                            name="estado"
                            onChange={this.handleEditChange}
                            value={this.state.edit.estado || ""}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </>
              ) : (
                <>
                  <div className="container">
                    <form
                      id=""
                      onSubmit={(e) => {
                        var dados = this.state.dados;
                        e.preventDefault();
                      }}
                    >
                      <div className="mt-3">
                        <label className="mb-2">Tipo de Cliente</label>
                        <select
                          className="form-control"
                          name="tipo_cliente"
                          onChange={this.handleEditChange}
                          value={this.state.edit.tipo_cliente || ""}
                        >
                          <option value="">Selecione</option>
                          <option value="1">Locação</option>
                          <option value="2">Venda</option>
                        </select>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <TextField
                            label="Nome"
                            name="nome"
                            onChange={this.handleEditChange}
                            value={this.state.edit.nome || ""}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                        <div className="col-md-6">
                          <TextField
                            label="Nome Fantasia"
                            name="nome_fantasia"
                            onChange={this.handleEditChange}
                            value={this.state.edit.nome_fantasia || ""}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <InputMask
                            name="cnpj_cpf"
                            onChange={this.handleEditChange}
                            value={this.state.edit.cnpj_cpf || ""}
                            mask="99.999.999/9999-99" // Máscara de telefone
                          >
                            {(inputProps) => (
                              <TextField
                                {...inputProps}
                                label="Cnpj"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  style: {
                                    height: 45,
                                    padding: "0 5px",
                                  },
                                }}
                                margin="normal"
                              />
                            )}
                          </InputMask>
                        </div>
                        <div className="col-md-6">
                          <InputMask
                            name="inscricao_estadual"
                            onChange={this.handleEditChange}
                            value={this.state.edit.inscricao_estadual || ""}
                            mask="99.999.999.99" // Máscara de telefone
                          >
                            {(inputProps) => (
                              <TextField
                                {...inputProps}
                                label="Inscricao Estadual"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  style: {
                                    height: 45,
                                    padding: "0 5px",
                                  },
                                }}
                                margin="normal"
                              />
                            )}
                          </InputMask>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <TextField
                            label="telefone"
                            name="telefone"
                            onChange={this.handleEditChange}
                            value={this.state.edit.telefone || ""}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                        <div className="col-md-6">
                          <InputMask
                            name="celular"
                            onChange={this.handleEditChange}
                            value={this.state.edit.celular || ""}
                            mask="(99) 9 9999-9999" // Máscara de telefone
                          >
                            {(inputProps) => (
                              <TextField
                                {...inputProps}
                                label="WhatsApp"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  style: {
                                    height: 45,
                                    padding: "0 5px",
                                  },
                                }}
                                margin="normal"
                              />
                            )}
                          </InputMask>
                        </div>
                      </div>
                      <div>
                        <TextField
                          label="E-mail"
                          name="email"
                          onChange={this.handleEditChange}
                          value={this.state.edit.email || ""}
                          variant="outlined" // Pode ser "filled" ou "standard"
                          fullWidth // O campo ocupa toda a largura disponível
                          margin="normal"
                          InputProps={{
                            style: {
                              height: 45,
                              padding: "0 5px",
                            },
                          }}
                        />
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <InputMask
                            name="cep"
                            value={this.state.edit.cep || ""}
                            onChange={async (e) => {
                              const cep = e.target.value.replace(/\D/g, ""); // Remove caracteres não numéricos

                              this.setState((prevState) => ({
                                edit: {
                                  ...prevState.edit, // Mantém os outros campos do objeto `dados`

                                  ["cep"]: cep,
                                },
                              }));

                              if (cep.length === 8) {
                                fetch(`https://viacep.com.br/ws/${cep}/json/`)
                                  .then((response) => response.json())
                                  .then((data) => {
                                    if (data.error == "true") {
                                      toast.warning("CEP inválido");
                                    } else {
                                      this.setState((prevState) => ({
                                        edit: {
                                          ...prevState.edit, // Mantém os outros campos do objeto `dados`

                                          ["endereco"]: data.logradouro, // Atualiza apenas o campo correspondente
                                          ["bairro"]: data.bairro,
                                          ["cidade"]: data.localidade,
                                          ["estado"]: data.estado,
                                        },
                                      }));
                                    }
                                  })
                                  .catch((error) => {
                                    toast.warning("CEP inválido");
                                  });
                              }
                            }}
                            mask="99.999-999" // Máscara de telefone
                          >
                            {(inputProps) => (
                              <TextField
                                {...inputProps}
                                label="CEP:"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  style: {
                                    height: 45,
                                    padding: "0 5px",
                                  },
                                }}
                                margin="normal"
                              />
                            )}
                          </InputMask>
                        </div>
                        <div className="col-md-6">
                          <TextField
                            label="Endereço"
                            name="endereco"
                            onChange={this.handleEditChange}
                            value={this.state.edit.endereco || ""}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <TextField
                            label="Número"
                            name="numero"
                            onChange={this.handleEditChange}
                            value={this.state.edit.numero || ""}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                        <div className="col-md-6">
                          <TextField
                            label="Bairro"
                            name="bairro"
                            onChange={this.handleEditChange}
                            value={this.state.edit.bairro || ""}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-6">
                          <TextField
                            label="Cidade"
                            name="cidade"
                            onChange={this.handleEditChange}
                            value={this.state.edit.cidade || ""}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                        <div className="col-md-6">
                          <TextField
                            label="Estado"
                            name="estado"
                            onChange={this.handleEditChange}
                            value={this.state.edit.estado || ""}
                            variant="outlined" // Pode ser "filled" ou "standard"
                            fullWidth // O campo ocupa toda a largura disponível
                            margin="normal"
                            InputProps={{
                              style: {
                                height: 45,
                                padding: "0 5px",
                              },
                            }}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </>
              )}
            </>
          )}

          <div className="modal-fotter">
            <button
              className="btn btn-primary"
              onClick={(e) => {
                var dados = this.state.edit;

                fetch(`${apiUrl}/editar-cliente`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    tipo_cliente: dados.tipo_cliente,
                    id: dados.id,
                    nome: dados.nome,
                    nome_fantasia: dados.nome_fantasia,
                    data_nascimento: dados.data_nascimento,
                    npai: dados.npai,
                    nmae: dados.nmae,
                    inscricao_estadual: dados.inscricao_estadual,
                    cnpj_cpf: dados.cnpj_cpf,
                    rg: dados.rg,
                    email: dados.email,
                    telefone: dados.telefone,
                    celular: dados.celular,
                    cep: dados.cep,
                    endereco: dados.endereco,
                    numero: dados.numero,
                    complemento: dados.complemento,
                    bairro: dados.bairro,
                    cidade: dados.cidade,
                    estado: dados.estado,
                  }), // corpo da requisição em formato JSON
                })
                  .then((response) => {
                    if (response.status == 200) {
                      response.json().then((data) => {
                        this.setState((prevState) => ({
                          items: prevState.items.map((item) =>
                            item.id === dados.id
                              ? {
                                  ...item,
                                  ...{
                                    tipo_cliente: dados.tipo_cliente,
                                    nome: dados.nome,
                                    nome_fantasia: dados.nome_fantasia,
                                    data_nascimento: dados.data_nascimento,
                                    cnpj_cpf: dados.cnpj_cpf,
                                    rg: dados.rg,
                                    npai: dados.npai,
                                    nmae: dados.nmae,
                                    inscricao_estadual:
                                      dados.inscricao_estadual,
                                    email: dados.email,
                                    telefone: dados.telefone,
                                    celular: dados.celular,
                                    cep: dados.cep,
                                    endereco: dados.endereco,
                                    numero: dados.numero,
                                    bairro: dados.bairro,
                                    complemento: dados.complemento,
                                    cidade: dados.cidade,
                                    estado: dados.estado,
                                  },
                                }
                              : item
                          ),
                          edit: {},
                          cpfCnpj: "",
                        }));

                        toast.success("Cliente alterado com sucesso!");
                      });
                    }
                  })
                  .catch((err) => {
                    toast.error("Falha na comunicação com o servidor!");
                  });
              }}
            >
              <FontAwesomeIcon icon={faSave} /> Salvar
            </button>
          </div>
        </StaticDialog>
        <StaticDialog
          isOpen={Object.keys(this.state.blockUser).length > 0}
          title="Bloquear Cliente"
          onAfterClose={(result) => {
            this.setState({ blockUser: {} });
          }}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              var form = e.target;
              var id = this.state.blockUser.id;
              var status = this.state.blockUser.client_status;

              fetch(`${apiUrl}/block-user`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  authorization: localStorage.getItem("token"),
                },
                body: JSON.stringify({
                  id,
                  status,
                  obs: this.state.blockUser.obs,
                }),
              }).then((response) => {
                if (response.status === 200) {
                  this.setState((prevState) => ({
                    items: prevState.items.map((item) =>
                      item.id === id ? { ...item, status } : item
                    ),
                    blockUser: {},
                  }));
                  toast.success("Bloqueado com sucesso!");
                }
              });
            }}
          >
            <div style={{ margin: 10 }}>
              <div className="mt-2 mb-3">
                status
                <select
                  className="form-control mt-3"
                  onChange={(e) => {
                    this.setState({
                      blockUser: {
                        ...this.state.blockUser,
                        client_status: e.target.value,
                      },
                    });
                  }}
                  value={this.state.blockUser.client_status}
                >
                  <option>Selecione:</option>
                  <option value="0">Desbloqueado</option>
                  <option value="1">Bloqueado Para Locação</option>
                  <option value="2">Bloqueado Para Serviço</option>
                  <option value="3">Bloqueio Total</option>
                </select>
              </div>
              <div className="mt-2 mb-3">
                <textarea
                  class="form-control"
                  placeholder="Observação"
                  onChange={(e) => {
                    this.setState({
                      blockUser: {
                        ...this.state.blockUser,
                        obs: e.target.value,
                      },
                    });
                  }}
                  value={this.state.blockUser.obs || ""}
                  style={{ height: "100px" }}
                />
              </div>
            </div>
            <div className="modal-fotter">
              <button type="submit" className="btn btn-primary">
                <FontAwesomeIcon icon={faSave} /> Salvar
              </button>
            </div>
          </form>
        </StaticDialog>
      </>
    );
  }
}

export default CadastroClientes;
