import React, { Component } from "react";
import { Link } from "react-router-dom";
import atencao from "../../img/atencao.svg";
import logo from "../../img/logo.png";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faBuilding,
  faCalendar,
  faCheck,
  faClipboard,
  faCoffee,
  faCog,
  faEnvelope,
  faEye,
  faFile,
  faFileAlt,
  faFileExcel,
  faFileInvoice,
  faFileText,
  faFlag,
  faHome,
  faIdCard,
  faMapMarkerAlt,
  faMinus,
  faMobileAlt,
  faPen,
  faPhone,
  faPrint,
  faRefresh,
  faSave,
  faSearch,
  faTrash,
  faTruck,
  faUser,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { StaticDialog, useDialog } from "react-st-modal";
import InputMask from "react-input-mask";
import { apiUrl } from "../../comps/apiUrl";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
class CurrentLease extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      add: false,
      dados: {},
      items: [],
      currentPage: 1,
      totalItems: 0,
      itemsPerPage: 100,
      comprovante: {},
      edit: {},
      trash: {},
      viewProducts: {},
      comprovante: {},
      pay: {},
      boletop: {},
      selectedFile: [],
      master: undefined,
      delmaster: {},
      f: "1",
      openDropdownId: null, // Guarda o ID do dropdown aberto
      selectedFiles: [],
      previews: [],
      Rlease: {},
      boletoe: {},
      currentPage: 1,
      itemsPerPage: 50, // Número de itens por página
      filteredItems: [],
      useFilter: false,
    };
    this.dropdownRefs = {}; // Armazena referências para os dropdowns
  }

  componentDidMount() {
    document.title = "Histórico de locações";
    this.fetchData(this.state.currentPage);
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);
      this.setState({ master: decoded.role });
    }
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  // Mudar de página
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  toggleDropdown = (id) => {
    this.setState((prevState) => ({
      openDropdownId: prevState.openDropdownId === id ? null : id, // Fecha se clicar de novo
    }));
  };
  formatarMoeda = (valor) => {
    // Remove caracteres que não sejam números
    const apenasNumeros = valor.replace(/\D/g, "");
    // Converte para número e formata em BRL
    const valorFormatado = (Number(apenasNumeros) / 100).toLocaleString(
      "pt-BR",
      {
        style: "currency",
        currency: "BRL",
      }
    );
    return valorFormatado;
  };
  handleClickOutside = (event) => {
    // Verifica se o clique foi fora do dropdown aberto
    if (
      this.state.openDropdownId !== null &&
      this.dropdownRefs[this.state.openDropdownId] &&
      !this.dropdownRefs[this.state.openDropdownId].contains(event.target)
    ) {
      this.setState({ openDropdownId: null });
    }
  };
  Payment_type(t) {
    if (t == "boletop") {
      return (
        <span class="badge bg-danger" style={{ cursor: "pointer" }}>
          <FontAwesomeIcon icon={faFlag} /> Boleto Pendente
        </span>
      );
    } else if (t == "boletoa") {
      return (
        <span class="badge bg-success" style={{ cursor: "pointer" }}>
          <FontAwesomeIcon icon={faFlag} /> Boleto Aprovado
        </span>
      );
    } else if (t == "pix") {
      return <span class="badge bg-success">Pix</span>;
    } else if (t == "boleto") {
      return <span class="badge bg-success">Boleto</span>;
    } else if (t == "cartaod") {
      return <span class="badge bg-success">Cartão de debito</span>;
    } else if (t == "cartaoc") {
      return <span class="badge bg-success">Cartão de credito</span>;
    } else if (t == "dinheiro") {
      return <span class="badge bg-success">Dinheiro</span>;
    } else if (t == "pne") {
      return (
        <span class="badge bg-danger" style={{ cursor: "pointer" }}>
          <FontAwesomeIcon icon={faFlag} /> Pagamento na Entrega
        </span>
      );
    } else if (t == "pnd") {
      return (
        <span class="badge bg-danger" style={{ cursor: "pointer" }}>
          <FontAwesomeIcon icon={faFlag} /> Pagamento na Devoluão
        </span>
      );
    } else if (t == "boletonp") {
      return (
        <span class="badge bg-dark" style={{ cursor: "pointer" }}>
          <FontAwesomeIcon icon={faFlag} /> Boleto Não Pago
        </span>
      );
    }
  }
  fetchData(page) {
    fetch(
      `${apiUrl}/current-lease?page=${page}&limit=${this.state.itemsPerPage}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    ).then((response) => {
      if (response.status === 510) {
        localStorage.clear();
        window.location.href = "/";
      }

      response.json().then((data) => {
        const totalItems = data.totalItems; // Simulado, substitua pelo valor real do backend.
        const totalPages = Math.ceil(totalItems / this.state.itemsPerPage);

        this.setState({
          items: data.items,
          currentPage: page,
          totalPages,
          loader: false,
        });
      });
    });
  }
  handleFileChange = (e) => {
    const files = Array.from(e.target.files);

    let validFiles = [];
    let previews = [];

    files.forEach((file) => {
      if (file.type.startsWith("image/")) {
        validFiles.push(file);
        previews.push(URL.createObjectURL(file));
      } else {
        toast.error("Apenas imagens são permitidas!");
      }
    });

    // Concatenar as novas imagens com as existentes
    this.setState((prevState) => ({
      selectedFiles: [...prevState.selectedFiles, ...validFiles],
      previews: [...prevState.previews, ...previews],
    }));
  };
  handlePageClick = (page) => {
    this.fetchData(page);
  };
  handleNextPage = () => {
    this.fetchData(this.state.currentPage + 1);
  };
  formatarDataBR(dataISO) {
    // Converte a string ISO em um objeto Date
    const data = new Date(dataISO);

    // Extrai as partes da data
    const dia = String(data.getDate()).padStart(2, "0");
    const mes = String(data.getMonth() + 1).padStart(2, "0"); // Meses começam do zero
    const ano = data.getFullYear();

    // Extrai as partes do horário
    const horas = String(data.getHours()).padStart(2, "0");
    const minutos = String(data.getMinutes()).padStart(2, "0");
    const secounds = String(data.getSeconds()).padStart(2, "0");

    // Retorna no formato brasileiro
    return `${dia}/${mes}/${ano} ${horas}:${minutos}:${secounds}`;
  }
  handlePreviousPage = () => {
    if (this.state.currentPage > 1) {
      this.fetchData(this.state.currentPage - 1);
    }
  };

  renderPagination() {
    const { currentPage, totalPages } = this.state;

    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => this.handlePageClick(i)}
          style={{
            backgroundColor: i === currentPage ? "#007bff" : "#fff",
            color: i === currentPage ? "#fff" : "#000",
            border: "1px solid #ddd",
            cursor: "pointer",
          }}
        >
          {i}
        </button>
      );
    }

    return (
      <div>
        <button
          className="btn btn-ligth"
          onClick={() => this.handlePageClick(currentPage - 1)}
          disabled={currentPage === 1}
          style={{
            cursor: currentPage === 1 ? "not-allowed" : "pointer",
          }}
        >
          &lt;
        </button>

        {pages}

        <button
          onClick={() => this.handlePageClick(currentPage + 1)}
          className="btn btn-ligth"
          disabled={currentPage === totalPages}
          style={{
            cursor: currentPage === totalPages ? "not-allowed" : "pointer",
          }}
        >
          &gt;
        </button>
      </div>
    );
  }
  handleRemoveImage = (index) => {
    const { selectedFiles, previews } = this.state;

    // Remover o arquivo e a pré-visualização do estado
    const updatedFiles = selectedFiles.filter((_, i) => i !== index);
    const updatedPreviews = previews.filter((_, i) => i !== index);

    this.setState({ selectedFiles: updatedFiles, previews: updatedPreviews });
  };
  handleChange = (event) => {
    const { id, value } = event.target;

    // Atualizando o estado dentro do objeto `dados`
    this.setState((prevState) => ({
      dados: {
        ...prevState.dados, // Mantém os outros campos do objeto `dados`
        [id]: value, // Atualiza apenas o campo correspondente
      },
    }));
  };

  handleEdit = (event) => {
    const { id, value } = event.target;

    // Atualizando o estado dentro do objeto `dados`
    this.setState((prevState) => ({
      edit: {
        ...prevState.edit, // Mantém os outros campos do objeto `dados`
        [id]: value, // Atualiza apenas o campo correspondente
      },
    }));
  };
  formatDate(dateString) {
    var e = dateString.split("-");
    var dateString = e[2] + "/" + e[1] + "/" + e[0];
    return dateString;
  }
  handleCpfCnpjChange = (e) => {
    function cnpj(v) {
      v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
      v = v.replace(/^(\d{2})(\d)/, "$1.$2"); //Coloca ponto entre o segundo e o terceiro dígitos
      v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3"); //Coloca ponto entre o quinto e o sexto dígitos
      v = v.replace(/\.(\d{3})(\d)/, ".$1/$2"); //Coloca uma barra entre o oitavo e o nono dígitos
      v = v.replace(/(\d{4})(\d)/, "$1-$2"); //Coloca um hífen depois do bloco de quatro dígitos
      return v;
    }

    function cpf(v) {
      v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
      v = v.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
      v = v.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
      //de novo (para o segundo bloco de números)
      v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2"); //Coloca um hífen entre o terceiro e o quarto dígitos
      return v;
    }

    if (e.target.value.length > 18) return;
    var value = e.target.value;
    if (e.target.value.length <= 14) {
      this.setState((prevState) => ({
        dados: {
          ...prevState.dados, // Mantém os outros campos do objeto `dados`
          ["doc"]: cpf(value), // Atualiza apenas o campo correspondente
        },
      }));
    } else {
      this.setState((prevState) => ({
        dados: {
          ...prevState.dados, // Mantém os outros campos do objeto `dados`
          ["doc"]: cnpj(value), // Atualiza apenas o campo correspondente
        },
      }));
    }
  };
  handleSearch = (e) => {
    e.preventDefault();

    const { filter, start_date, end_date, faturamento, items } = this.state;

    if (filter != 1) {
      if (!start_date) {
        toast.warn("Informe a data de início");
        return;
      }

      if (!end_date) {
        toast.warn("Informe a data de fim");
        return;
      }
    }

    if (filter == 2 && !faturamento) {
      toast.warn("Selecione o método de pagamento");
      return;
    }

    if (filter == 1 && !faturamento) {
      this.setState({ filteredItems: [] });
      return;
    }

    if (filter == 7 && !faturamento) {
      toast.warn("Selecione o método de pagamento");
      return;
    }

    let filteredItems = items;

    if (filter == 1) {
      filteredItems = items.filter((item) =>
        item.client_name.toLowerCase().includes(faturamento.toLowerCase())
      );
    } else if (filter == 2) {
      filteredItems = items.filter((item) => item.billing == faturamento);
    } else if (filter == 3) {
      filteredItems = items.filter(
        (item) =>
          item.data_locacao >= start_date && item.data_locacao <= end_date
      );
    } else if (filter == 4) {
      filteredItems = items.filter(
        (item) =>
          item.data_vencimento >= start_date && item.data_vencimento <= end_date
      );
    } else if (filter == 6) {
      filteredItems = items.filter(
        (item) =>
          item.data_pagamento >= start_date && item.data_pagamento <= end_date
      );
    } else if (filter == 7) {
      filteredItems = items.filter(
        (item) => item.metodo_pagamento == faturamento
      );
    }

    this.setState({
      filteredItems,
      currentPage: 1,
      useFilter: true,
    });
  };
  render() {
    if (this.state.loader) {
      return (
        <div class="area-loader">
          <div class="loader"></div>
        </div>
      );
    }
    var invoicingType = {
      1: "Diária",
      2: "Semanal",
      3: "Quizenal",
      4: "Mensal",
      5: "Anual",
    };

    var priceType = {
      1: "day_price",
      2: "weekly_price",
      3: "biweekly_price",
      4: "monthly_price",
      5: "annual_price",
    };

    const { items, currentPage, itemsPerPage } = this.state;
    const totalPages = Math.ceil(items.length / itemsPerPage);

    // Ordena os itens, garantindo estabilidade na ordenação
    const sortedItems = [...items].sort((a, b) => {
      if (a.retirada === 1 && b.retirada !== 1) return -1;
      if (a.retirada !== 1 && b.retirada === 1) return 1;
      return 0; // Mantém a ordem original entre os que não têm retirada: 1
    });

    // Filtra os itens da página atual
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = sortedItems.slice(indexOfFirstItem, indexOfLastItem);

    return (
      <>
        <div class="content">
          <div className="card mb-3">
            <div className="card-header">
              <strong>Pesquisa</strong>
            </div>
            <div className="card-body">
              <form onSubmit={this.handleSearch} className="">
                <div className="mb-3 text-center">
                  <div className="row justify-content-center">
                    <div className="col-md-4">
                      <select
                        className="form-control"
                        onChange={(e) =>
                          this.setState({
                            filter: e.target.value,
                            faturamento: undefined,
                          })
                        }
                      >
                        <option value="">Selecione:</option>
                        <option value="1">Cliente</option>
                        <option value="2">Faturamento</option>
                        <option value="3">Data da Locação</option>
                        <option value="4">Data de Vencimento</option>
                        <option value="6">Data de Pagamento</option>
                        <option value="7">Método de Pagamento</option>
                      </select>
                    </div>
                    <div className="col-md-4">
                      <input
                        type="date"
                        className="form-control"
                        onChange={(e) =>
                          this.setState({ start_date: e.target.value })
                        }
                      />
                    </div>
                    <div className="col-md-4">
                      <input
                        type="date"
                        className="form-control"
                        onChange={(e) =>
                          this.setState({ end_date: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-3 text-center">
                  <div className="row justify-content-center">
                    <div className="col-md-6">
                      {this.state.filter === "7" && (
                        <select
                          className="form-control"
                          onChange={(e) =>
                            this.setState({ faturamento: e.target.value })
                          }
                        >
                          <option value="">Selecione</option>
                          <option value="pix">PIX</option>
                          <option value="cartaoc">Cartão de Crédito</option>
                          <option value="cartaod">Cartão de Débito</option>
                          <option value="boletop">Boleto Emitido</option>
                          <option value="pne">Pagamento na Entrega</option>

                          <option value="dinheiro">Dinheiro</option>

                          <option value="todos">Todos</option>
                        </select>
                      )}
                      {this.state.filter === "1" && (
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Digite o nome do cliente"
                          onChange={(e) => {
                            if (e.target.value.length == 0) {
                              this.setState({
                                useFilter: false,
                                filteredItems: [],
                              });
                            }
                            this.setState({ faturamento: e.target.value });
                          }}
                        />
                      )}
                      {this.state.filter === "2" && (
                        <select
                          className="form-control"
                          onChange={(e) =>
                            this.setState({ faturamento: e.target.value })
                          }
                        >
                          <option value="">Selecione</option>
                          <option value="1">Diária</option>
                          <option value="2">Semanal</option>
                          <option value="3">Quinzenal</option>
                          <option value="4">Mensal</option>
                          <option value="5">Anual</option>
                          <option value="todos">Todos</option>
                        </select>
                      )}
                    </div>
                    <div className="col-md-2">
                      <button className="btn btn-primary" type="submit">
                        <FontAwesomeIcon icon={faSearch} /> Buscar
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="card">
            <div class="card-header d-flex align-items-center justify-content-between">
              <strong>Locações</strong>
              {this.state.master != undefined && (
                <>
                  <Link to="/lease-history/deleted">
                    Histórico de Locações Deletadas
                  </Link>
                </>
              )}
            </div>
            <div class="card-body">
              <div>
                {this.state.useFilter ? (
                  <>
                    {this.state.filteredItems.length > 0 ? (
                      <>
                        <table className="table table-striped table-bordered arvore-l">
                          <thead className="thead-dark">
                            <tr>
                              <th>Tipo de Operação</th>
                              <th>Funcionário(a)</th>
                              <th>Cliente</th>
                              <th>Faturamento da Locação</th>
                              <th>Valor Locação</th>
                              <th>Data da Locação</th>
                              <th>Data de Vencimento</th>
                              <th>Status de Vencimento</th>
                              <th>Data de Pagamento</th>
                              <th>Metodo de Pagamento</th>
                              <th>Ações</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.filteredItems.map((item, index) => (
                              <tr key={item.id}>
                                <td>
                                  {item.tdo === 1 ? "Locação" : "Renovação"}
                                </td>
                                <td>{item.seller_name}</td>
                                <td>
                                  <Link
                                    to={`/cadastro-clientes/view/${item.client_id}`}
                                    style={{ textDecoration: "none" }}
                                  >
                                    {item.client_name}
                                  </Link>
                                </td>
                                <td>{invoicingType[item.billing]}</td>
                                <td>
                                  {item.location_value.toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                  })}
                                </td>
                                <td>{this.formatarDataBR(item.start_day)}</td>
                                <td>{this.formatarDataBR(item.end_day)}</td>
                                <td>
                                  {item.retirada === 1 && (
                                    <div className="retirada">
                                      <FontAwesomeIcon
                                        icon={faTruck}
                                        color="#fff"
                                      />
                                    </div>
                                  )}
                                  <span
                                    className="badge"
                                    style={{
                                      background: item.order.bg,
                                      color: item.order.color,
                                    }}
                                  >
                                    {item.order.message}
                                  </span>
                                </td>
                                <td>
                                  {item.payment_a
                                    ? this.formatarDataBR(item.payment_a)
                                    : "---"}
                                </td>
                                <td>
                                  {item.payment_type == "boletoe" && (
                                    <>
                                      {" "}
                                      <span
                                        class="badge"
                                        onClick={() => {
                                          this.setState({ boletoe: item });
                                        }}
                                        style={{
                                          cursor: "pointer",
                                          background: "#ae2be2",
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faFlag} /> Boleto
                                        Emitido
                                      </span>
                                    </>
                                  )}

                                  {item.payment_type == "pnd" ||
                                  item.payment_type == "pne" ? (
                                    <div
                                      onClick={(e) => {
                                        this.setState({ pay: item });
                                      }}
                                    >
                                      {this.Payment_type(item.payment_type)}
                                    </div>
                                  ) : item.payment_type == "boletop" ? (
                                    <div
                                      onClick={(e) => {
                                        this.setState({ boletop: item });
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <span class="badge bg-danger">
                                        Boleto Pendente
                                      </span>
                                    </div>
                                  ) : (
                                    this.Payment_type(item.payment_type)
                                  )}
                                </td>

                                <td style={{ position: "relative" }}>
                                  <div
                                    key={item.id}
                                    ref={(el) =>
                                      (this.dropdownRefs[item.id] = el)
                                    } // Salva a referência do dropdown
                                    style={{
                                      position: "relative",
                                      display: "inline-block",
                                      marginRight: "10px",
                                    }}
                                  >
                                    <button
                                      className="btn btn-primary"
                                      onClick={() =>
                                        this.toggleDropdown(item.id)
                                      }
                                      style={{
                                        padding: "10px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Selecione
                                    </button>

                                    {this.state.openDropdownId === item.id && (
                                      <div
                                        className="dropdown-menu-rb"
                                        style={{ left: -220 }}
                                      >
                                        <div className="d-flex justify-content-center align-items-center">
                                          <button
                                            className="btn"
                                            tooltip="Autorizar Retirada"
                                            style={{
                                              background: " #e2782b",
                                              marginRight: "10px",
                                            }}
                                            onClick={(e) => {
                                              this.setState({ Rlease: item });
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon={faTruck}
                                              color="#fff"
                                            />
                                          </button>

                                          <Link
                                            tooltip="Detalhes"
                                            to={`/location/details/${item.token}`}
                                            target="_blank"
                                            style={{ marginRight: "10px" }}
                                            className="btn btn-primary"
                                          >
                                            <FontAwesomeIcon icon={faSearch} />
                                          </Link>

                                          <Link
                                            tooltip="Renovar"
                                            to={`/lease/renew-location/${item.token}`}
                                            target="_blank"
                                            className="btn btn-primary"
                                          >
                                            <FontAwesomeIcon
                                              icon={faCalendar}
                                            />
                                          </Link>
                                          <Link
                                            tooltip="Contrato"
                                            to={`/lease/contract/${item.token}`}
                                            target="_blank"
                                            className="btn"
                                            style={{
                                              marginLeft: "10px",
                                              background: "#8A2BE2",
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              color="#fff"
                                              icon={faPrint}
                                            />
                                          </Link>
                                          <button
                                            tooltip="Finalziar Locação"
                                            className="btn btn-success"
                                            style={{ marginLeft: "10px" }}
                                            onClick={() => {
                                              this.setState({
                                                comprovante: item,
                                              });
                                            }}
                                          >
                                            <FontAwesomeIcon icon={faCheck} />
                                          </button>
                                          {this.state.master != undefined && (
                                            <div style={{ marginLeft: "10px" }}>
                                              <button
                                                tooltip="Excluir Locação"
                                                className="btn btn-danger"
                                                onClick={() => {
                                                  console.log("teste");
                                                  this.setState({
                                                    delmaster: item,
                                                  });
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  icon={faTrash}
                                                />
                                              </button>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <div className="text-center">
                        <strong>Não foram encontrados registros</strong>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {" "}
                    {items.length > 0 ? (
                      <>
                        <div className="d-flex justify-content-center align-items-center gap-2 mb-3">
                          <button
                            className="btn btn-outline-primary"
                            disabled={currentPage === 1}
                            onClick={() =>
                              this.handlePageChange(currentPage - 1)
                            }
                            style={{
                              borderRadius: "20px",
                              padding: "8px 15px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <FontAwesomeIcon icon={faArrowLeft} /> {""} Anterior
                          </button>

                          {Array.from({ length: totalPages }, (_, i) => (
                            <button
                              key={i + 1}
                              onClick={() => this.handlePageChange(i + 1)}
                              className={`btn ${
                                currentPage === i + 1
                                  ? "btn-primary text-white"
                                  : "btn-outline-primary"
                              }`}
                              style={{
                                borderRadius: "50%",
                                width: "40px",
                                height: "40px",
                                fontWeight: "bold",
                              }}
                            >
                              {i + 1}
                            </button>
                          ))}

                          <button
                            className="btn btn-outline-primary"
                            disabled={currentPage === totalPages}
                            onClick={() =>
                              this.handlePageChange(currentPage + 1)
                            }
                            style={{
                              borderRadius: "20px",
                              padding: "8px 15px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            Próximo <FontAwesomeIcon icon={faArrowRight} />
                          </button>
                        </div>

                        <table className="table table-striped table-bordered arvore-l">
                          <thead className="thead-dark">
                            <tr>
                              <th>Tipo de Operação</th>
                              <th>Funcionário(a)</th>
                              <th>Cliente</th>
                              <th>Faturamento da Locação</th>
                              <th>Valor Locação</th>
                              <th>Data da Locação</th>
                              <th>Data de Vencimento</th>
                              <th>Status de Vencimento</th>
                              <th>Data de Pagamento</th>
                              <th>Metodo de Pagamento</th>
                              <th>Ações</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentItems.map((item, index) => (
                              <tr key={item.id}>
                                <td>
                                  {item.tdo === 1 ? "Locação" : "Renovação"}
                                </td>
                                <td>{item.seller_name}</td>
                                <td>
                                  <Link
                                    to={`/cadastro-clientes/view/${item.client_id}`}
                                    style={{ textDecoration: "none" }}
                                  >
                                    {item.client_name}
                                  </Link>
                                </td>
                                <td>{invoicingType[item.billing]}</td>
                                <td>
                                  {item.location_value.toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                  })}
                                </td>
                                <td>{this.formatarDataBR(item.start_day)}</td>
                                <td>{this.formatarDataBR(item.end_day)}</td>
                                <td>
                                  {item.retirada === 1 && (
                                    <div className="retirada">
                                      <FontAwesomeIcon
                                        icon={faTruck}
                                        color="#fff"
                                      />
                                    </div>
                                  )}
                                  <span
                                    className="badge"
                                    style={{
                                      background: item.order.bg,
                                      color: item.order.color,
                                    }}
                                  >
                                    {item.order.message}
                                  </span>
                                </td>
                                <td>
                                  {item.payment_a
                                    ? this.formatarDataBR(item.payment_a)
                                    : "---"}
                                </td>
                                <td>
                                  {item.payment_type == "boletoe" && (
                                    <>
                                      {" "}
                                      <span
                                        class="badge"
                                        onClick={() => {
                                          this.setState({ boletoe: item });
                                        }}
                                        style={{
                                          cursor: "pointer",
                                          background: "#ae2be2",
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faFlag} /> Boleto
                                        Emitido
                                      </span>
                                    </>
                                  )}

                                  {item.payment_type == "pnd" ||
                                  item.payment_type == "pne" ? (
                                    <div
                                      onClick={(e) => {
                                        this.setState({ pay: item });
                                      }}
                                    >
                                      {this.Payment_type(item.payment_type)}
                                    </div>
                                  ) : item.payment_type == "boletop" ? (
                                    <div
                                      onClick={(e) => {
                                        this.setState({ boletop: item });
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <span class="badge bg-danger">
                                        Boleto Pendente
                                      </span>
                                    </div>
                                  ) : (
                                    this.Payment_type(item.payment_type)
                                  )}
                                </td>

                                <td style={{ position: "relative" }}>
                                  <div
                                    key={item.id}
                                    ref={(el) =>
                                      (this.dropdownRefs[item.id] = el)
                                    } // Salva a referência do dropdown
                                    style={{
                                      position: "relative",
                                      display: "inline-block",
                                      marginRight: "10px",
                                    }}
                                  >
                                    <button
                                      className="btn btn-primary"
                                      onClick={() =>
                                        this.toggleDropdown(item.id)
                                      }
                                      style={{
                                        padding: "10px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Selecione
                                    </button>

                                    {this.state.openDropdownId === item.id && (
                                      <div
                                        className="dropdown-menu-rb"
                                        style={{ left: -220 }}
                                      >
                                        <div className="d-flex justify-content-center align-items-center">
                                          <button
                                            className="btn"
                                            tooltip="Autorizar Retirada"
                                            style={{
                                              background: " #e2782b",
                                              marginRight: "10px",
                                            }}
                                            onClick={(e) => {
                                              this.setState({ Rlease: item });
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon={faTruck}
                                              color="#fff"
                                            />
                                          </button>

                                          <Link
                                            tooltip="Detalhes"
                                            to={`/location/details/${item.token}`}
                                            target="_blank"
                                            style={{ marginRight: "10px" }}
                                            className="btn btn-primary"
                                          >
                                            <FontAwesomeIcon icon={faSearch} />
                                          </Link>

                                          <Link
                                            tooltip="Renovar"
                                            to={`/lease/renew-location/${item.token}`}
                                            target="_blank"
                                            className="btn btn-primary"
                                          >
                                            <FontAwesomeIcon
                                              icon={faCalendar}
                                            />
                                          </Link>
                                          <Link
                                            tooltip="Contrato"
                                            to={`/lease/contract/${item.token}`}
                                            target="_blank"
                                            className="btn"
                                            style={{
                                              marginLeft: "10px",
                                              background: "#8A2BE2",
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              color="#fff"
                                              icon={faPrint}
                                            />
                                          </Link>
                                          <button
                                            tooltip="Finalziar Locação"
                                            className="btn btn-success"
                                            style={{ marginLeft: "10px" }}
                                            onClick={() => {
                                              this.setState({
                                                comprovante: item,
                                              });
                                            }}
                                          >
                                            <FontAwesomeIcon icon={faCheck} />
                                          </button>
                                          {this.state.master != undefined && (
                                            <div style={{ marginLeft: "10px" }}>
                                              <button
                                                tooltip="Excluir Locação"
                                                className="btn btn-danger"
                                                onClick={() => {
                                                  console.log("teste");
                                                  this.setState({
                                                    delmaster: item,
                                                  });
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  icon={faTrash}
                                                />
                                              </button>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>

                        <div className="d-flex justify-content-center align-items-center gap-2 mb-3">
                          <button
                            className="btn btn-outline-primary"
                            disabled={currentPage === 1}
                            onClick={() =>
                              this.handlePageChange(currentPage - 1)
                            }
                            style={{
                              borderRadius: "20px",
                              padding: "8px 15px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <FontAwesomeIcon icon={faArrowLeft} /> {""} Anterior
                          </button>

                          {Array.from({ length: totalPages }, (_, i) => (
                            <button
                              key={i + 1}
                              onClick={() => this.handlePageChange(i + 1)}
                              className={`btn ${
                                currentPage === i + 1
                                  ? "btn-primary text-white"
                                  : "btn-outline-primary"
                              }`}
                              style={{
                                borderRadius: "50%",
                                width: "40px",
                                height: "40px",
                                fontWeight: "bold",
                              }}
                            >
                              {i + 1}
                            </button>
                          ))}

                          <button
                            className="btn btn-outline-primary"
                            disabled={currentPage === totalPages}
                            onClick={() =>
                              this.handlePageChange(currentPage + 1)
                            }
                            style={{
                              borderRadius: "20px",
                              padding: "8px 15px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            Próximo <FontAwesomeIcon icon={faArrowRight} />
                          </button>
                        </div>
                      </>
                    ) : (
                      <>Não há resultados</>
                    )}
                  </>
                )}
              </div>
            </div>

            <div
              className="card-footer"
              style={{ display: "flex", justifyContent: "center" }}
            ></div>
          </div>
        </div>

        <StaticDialog
          isOpen={Object.keys(this.state.boletoe).length > 0}
          title="Informações de pagamento"
          onAfterClose={(result) => {
            this.setState({ boletoe: {} });
          }}
        >
          <div style={{ margin: 10 }}>
            <div>
              <label>Forma de Pagamento</label>
              <select
                id="tpay"
                class="form-control mt-2"
                onChange={(e) => {
                  this.setState({ payment_type: e.target.value });
                }}
                required=""
              >
                <option value="" disabled="" selected="">
                  Selecione
                </option>
                <option value="boletoa">Boleto Aprovado</option>
                <option value="boletonp">Boleto Não Pago</option>
              </select>
            </div>
          </div>
          <div className="modal-fotter">
            <button
              className="btn btn-primary"
              onClick={(e) => {
                if (
                  this.state.payment_type == "" ||
                  this.state.payment_type == undefined
                ) {
                  toast.warn("Por favor, selecione a forma de pagamento!");
                  return;
                }

                e.target.classList.add("load");
                var payment_type = this.state.payment_type;
                var token = this.state.boletoe.token;
                fetch(
                  `${apiUrl}/lease/boleto/${this.state.boletoe.token}/payment`,
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      authorization: localStorage.getItem("token"),
                    },
                    body: JSON.stringify({
                      payment_type,
                    }),
                  }
                ).then((response) => {
                  e.target.classList.remove("load");
                  if (response.status === 200) {
                    this.setState((prevState) => ({
                      items: prevState.items.map((produto) =>
                        produto.token === token
                          ? {
                              ...produto,
                              payment_type,
                              payment_a:
                                payment_type == "boletoa"
                                  ? new Date()
                                  : undefined,
                            }
                          : produto
                      ),
                      boletoe: {},
                    }));
                    toast.success("Pagamento atualizado com sucesso!");
                  }
                });
              }}
            >
              <FontAwesomeIcon icon={faSave} /> Salvar
            </button>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={Object.keys(this.state.pay).length > 0}
          title="Informações de pagamento"
          onAfterClose={(result) => {
            this.setState({ pay: {} });
          }}
        >
          <div style={{ margin: 10 }}>
            <div>
              <label>Forma de Pagamento</label>
              <select
                id="tpay"
                class="form-control mt-2"
                onChange={(e) => {
                  this.setState({ payment_type: e.target.value });
                }}
                required=""
              >
                <option value="" disabled="" selected="">
                  Selecione
                </option>

                <option value="cartaoc">Cartão de Crédito</option>
                <option value="cartaod">Cartão de Débito</option>
                <option value="dinheiro">Dinheiro</option>
                <option value="pix">PIX</option>
              </select>
            </div>
          </div>
          <div className="modal-fotter">
            <button
              className="btn btn-primary"
              onClick={(e) => {
                if (
                  this.state.payment_type == "" ||
                  this.state.payment_type == undefined
                ) {
                  toast.warn("Por favor, selecione a forma de pagamento!");
                  return;
                }

                e.target.classList.add("load");
                var payment_type = this.state.payment_type;
                var token = this.state.pay.token;
                fetch(`${apiUrl}/lease/${this.state.pay.token}/payment`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    authorization: localStorage.getItem("token"),
                  },
                  body: JSON.stringify({
                    payment_type,
                  }),
                }).then((response) => {
                  e.target.classList.remove("load");
                  if (response.status === 200) {
                    this.setState((prevState) => ({
                      items: prevState.items.map((produto) =>
                        produto.token === token
                          ? { ...produto, payment_type, payment_a: new Date() }
                          : produto
                      ),
                      pay: {},
                    }));
                    toast.success("Pagamento efetuado com sucesso!");
                  }
                });
              }}
            >
              <FontAwesomeIcon icon={faSave} /> Salvar
            </button>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={Object.keys(this.state.boletop).length > 0}
          title="Informações de pagamento"
          onAfterClose={(result) => {
            this.setState({ boletop: {} });
          }}
        >
          <div style={{ margin: 10 }}>
            <div>
              <label>Forma de Pagamento</label>
              <select
                id="tpay"
                class="form-control mt-2"
                onChange={(e) => {
                  this.setState({ payment_type: e.target.value });
                }}
                required=""
              >
                <option value="" disabled="" selected="">
                  Selecione
                </option>
                <option value="boletoa">Boleto Aprovado</option>
                <option value="boletoe">Boleto Emitido</option>
              </select>
            </div>
          </div>
          <div className="modal-fotter">
            <button
              className="btn btn-primary"
              onClick={(e) => {
                if (
                  this.state.payment_type == "" ||
                  this.state.payment_type == undefined
                ) {
                  toast.warn("Por favor, selecione a forma de pagamento!");
                  return;
                }

                e.target.classList.add("load");
                var payment_type = this.state.payment_type;
                var token = this.state.boletop.token;
                fetch(`${apiUrl}/lease/${this.state.boletop.token}/payment`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    authorization: localStorage.getItem("token"),
                  },
                  body: JSON.stringify({
                    payment_type,
                  }),
                }).then((response) => {
                  e.target.classList.remove("load");
                  if (response.status === 200) {
                    this.setState((prevState) => ({
                      items: prevState.items.map((produto) =>
                        produto.token === token
                          ? { ...produto, payment_type, payment_a: new Date() }
                          : produto
                      ),
                      boletop: {},
                    }));
                    toast.success("Pagamento efetuado com sucesso!");
                  }
                });
              }}
            >
              <FontAwesomeIcon icon={faSave} /> Salvar
            </button>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={Object.keys(this.state.comprovante).length > 0}
          title="Informações de Devolução"
          onAfterClose={(result) => {
            this.setState({ comprovante: {} });
          }}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();

              var form = e.target;
              const formData = new FormData();

              // Adiciona os dados do formulário ao FormData
              console.log(this.state.selectedFile.length);
              if (this.state.comprovante.state == undefined) {
                toast.warn("Por favor, selecione o estado da devolução!");
                return;
              }

              formData.append("client", this.state.comprovante.client_id);
              formData.append("obs_end", this.state.comprovante.desc);
              formData.append("state", this.state.comprovante.state);
              formData.append("fine", this.state.comprovante.fine);
              if (this.state.previews.length > 0) {
                this.state.selectedFiles.forEach((file, index) => {
                  formData.append("images", file);
                });
              }
              var resp = fetch(
                `${apiUrl}/finalizar-locacao/${
                  this.state.previews.length > 0
                    ? this.state.comprovante.token + "/files"
                    : this.state.comprovante.token
                }`,
                {
                  method: "POST",
                  headers: {
                    authorization: localStorage.getItem("token"),
                  },
                  body: formData,
                }
              );

              resp
                .then((response) => {
                  if (response.status == 200) {
                    form[3].classList.remove("load");
                    this.setState((prevState) => ({
                      items: prevState.items.filter(
                        (item) => item.token !== this.state.comprovante.token
                      ),
                      comprovante: {},
                      selectedFiles: [],
                      selectedFile: [],
                      previews: [],
                    }));

                    toast.success("Locação finalizada com sucesso!");
                  }
                })
                .catch((error) => {
                  form[3].classList.remove("load");
                  toast.error("Erro ao finalizar locação!");
                });
            }}
          >
            <div className="content">
              <div class="mb-3">
                <label for="nome-cliente" class="form-label">
                  Nome do Cliente
                </label>
                <input
                  type="text"
                  id="nome-cliente"
                  class="form-control"
                  value={this.state.comprovante.client_name}
                  disabled
                />
              </div>
              <div className="mb-1">
                <label class="form-label">Estado do Equipamento</label>
                <select
                  className="form-control"
                  onChange={(e) => {
                    var v = e.target.value;
                    if (v == "") {
                      return;
                    }
                    if (this.state.comprovante.state == "1") {
                      this.setState({ selectedFile: [] });
                    }

                    this.setState((prevState) => ({
                      comprovante: {
                        ...prevState.comprovante,
                        ["state"]: e.target.value, // Atualiza apenas o campo especificado
                      },
                    }));
                  }}
                  value={this.state.comprovante.state || ""}
                >
                  <option value="">Selecione:</option>
                  <option value="1">Em Ordem</option>
                  <option value="2">Avariado</option>
                </select>
              </div>
              <div className="mb-3">
                <label>Multa</label>
                <input
                  type="text"
                  className="form-control mt-1"
                  value={this.state.comprovante.fine || "R$ 0,00"}
                  onChange={(e) => {
                    this.setState((prevState) => ({
                      comprovante: {
                        ...prevState.comprovante,
                        ["fine"]: this.formatarMoeda(e.target.value), // Atualiza apenas o campo especificado
                      },
                    }));
                  }}
                />
              </div>
              {this.state.comprovante.state == "2" && (
                <>
                  <div className="mt-1 mb-3">
                    <label className="mb-2">Documento:</label>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",

                        flexWrap: "wrap",
                      }}
                    >
                      {this.state.previews.map((src, index) => (
                        <div
                          key={index}
                          style={{
                            position: "relative",
                            display: "inline-block",
                          }}
                        >
                          <img
                            src={src}
                            alt={`preview-${index}`}
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                              border: "1px solid #ccc",
                              borderRadius: "5px",
                            }}
                          />
                          <button
                            onClick={() => this.handleRemoveImage(index)}
                            style={{
                              position: "absolute",
                              top: "5px",
                              right: "5px",
                              background: "red",
                              color: "white",
                              border: "none",
                              borderRadius: "50%",
                              width: "20px",
                              height: "20px",
                              cursor: "pointer",
                              fontSize: "14px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <FontAwesomeIcon icon={faMinus} color="#fff" />
                          </button>
                        </div>
                      ))}
                    </div>
                    <div
                      className="custom-upl"
                      style={{
                        marginTop:
                          this.state.previews.length > 0 ? "20px" : "0",
                      }}
                    >
                      <input
                        type="file"
                        multiple
                        accept="image/*,application/pdf" // Isso já ajuda a restringir para imagens no navegador
                        onChange={this.handleFileChange}
                      />
                      <button className="btn btn-primary">
                        <FontAwesomeIcon icon={faFileAlt} /> Anexar Documento
                      </button>
                    </div>
                  </div>
                </>
              )}
              <div>
                <textarea
                  class="form-control"
                  placeholder="Observação"
                  value={this.state.comprovante.desc || ""}
                  onChange={(e) => {
                    this.setState((prevState) => ({
                      comprovante: {
                        ...prevState.comprovante,
                        ["desc"]: e.target.value, // Atualiza apenas o campo especificado
                      },
                    }));
                  }}
                  style={{ height: "100px" }}
                />
              </div>
            </div>
            <div className="modal-fotter">
              <button type="submit" className="btn btn-primary">
                <FontAwesomeIcon icon={faSave} /> Finalizar Locação
              </button>
            </div>
          </form>
        </StaticDialog>

        <StaticDialog
          isOpen={Object.keys(this.state.Rlease).length > 0}
          title="Autorizar Retirada"
          onAfterClose={(result) => {
            this.setState({ Rlease: {} });
          }}
        >
          <div style={{ margin: 10 }}>
            <span>
              Os equipamentos estão prontos para retirada em nossa loja.
              Confirme a retirada e registre qualquer observação necessária
              antes de concluir o processo
            </span>
            <textarea
              class="form-control mt-3 mb-3"
              placeholder="Observação"
              value={this.state.Rlease.desc || ""}
              onChange={(e) => {
                this.setState((prevState) => ({
                  Rlease: {
                    ...prevState.Rlease,
                    ["desc"]: e.target.value, // Atualiza apenas o campo especificado
                  },
                }));
              }}
              style={{ height: "100px" }}
            ></textarea>
          </div>
          <div className="modal-fotter">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={(e) => {
                e.target.classList.add("load");
                var token = this.state.Rlease.token;
                fetch(`${apiUrl}/retirada`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    authorization: localStorage.getItem("token"),
                    // outras headers, se necessário
                  },
                  body: JSON.stringify({
                    id: this.state.Rlease.id,
                    token,
                    desc: this.state.Rlease.desc,
                  }), // corpo da requisição em formato JSON
                })
                  .then((res) => {
                    e.target.classList.remove("load");
                    if (res.status === 200) {
                      this.setState((prevState) => ({
                        items: prevState.items.map((produto) =>
                          produto.token === token
                            ? { ...produto, retirada: 1 }
                            : produto
                        ),
                        Rlease: {},
                      }));
                    }
                  })
                  .catch((err) => {
                    e.target.classList.remove("load");
                    toast.error(err);
                  });
              }}
            >
              <FontAwesomeIcon icon={faSave} /> Concluir
            </button>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={Object.keys(this.state.delmaster).length > 0}
          title="Tem certeza de que deseja continuar?"
          onAfterClose={(result) => {
            this.setState({ delmaster: {} });
          }}
        >
          <div style={{ margin: 10 }}>
            Se vocé confirmar a exclusão, não sera possivel recuperar os dados
            posteriormente.
          </div>

          <div className="modal-fotter">
            <button
              className="btn btn-danger"
              onClick={(e) => {
                e.target.classList.add("load");

                var id = this.state.delmaster.id;

                fetch(`${apiUrl}/delete-lease`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    authorization: localStorage.getItem("token"),
                    // outras headers, se necessário
                  },
                  body: JSON.stringify({ id }), // corpo da requisição em formato JSON
                })
                  .then((response) => {
                    e.target.classList.remove("load");
                    if (response.status == 200) {
                      toast.success("Locação excluida com sucesso!");
                      this.setState((prevState) => ({
                        items: prevState.items.filter((item) => item.id !== id),
                        delmaster: {},
                      }));
                    }
                  })
                  .catch((error) => {
                    toast.error("Erro ao enviar dados:", error);
                  });
              }}
            >
              <FontAwesomeIcon icon={faTrash} /> Excluir
            </button>
          </div>
        </StaticDialog>
      </>
    );
  }
}

export default CurrentLease;
