import React, { Component } from "react";
import { apiUrl } from "../../../comps/apiUrl";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faFile,
  faFileAlt,
  faPen,
  faPrint,
  faSave,
  fawhatsapp,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import alert from "../../../img/alert.svg";
import logomarca from "../../../img/logomarca.jpg";
import withLocation from "../../../comps/withLocation";
import { StaticDialog } from "react-st-modal";
import { TextField } from "@mui/material";
class LeaserContract extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      loader: true,
      data: [],
      store: {},
      client: {},
      location: {},
      products: {},
      edit: {},
    };
    this.page = React.createRef();
  }

  componentDidMount() {
    document.title = "Visualizar locação";
    fetch(`${apiUrl}/lease/contract/${this.props.params.token}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          this.setState({
            loader: false,
            error: true,
          });
        }
        this.setState({
          data: data,
          store: data.store,
          client: data.client,
          location: data.location,
          products: data.products,
          contract: data.contract,
          loader: false,
        });
      })
      .catch((error) => {
        toast.error("Ocorreu um erro ao carregar as maquinas");
      });
  }

  formatarDataBR(dataISO) {
    // Converte a string ISO em um objeto Date
    const data = new Date(dataISO);

    // Extrai as partes da data
    const dia = String(data.getDate()).padStart(2, "0");
    const mes = String(data.getMonth() + 1).padStart(2, "0"); // Meses começam do zero
    const ano = data.getFullYear();

    // Extrai as partes do horário
    const horas = String(data.getHours()).padStart(2, "0");
    const minutos = String(data.getMinutes()).padStart(2, "0");
    const seconds = String(data.getSeconds()).padStart(2, "0");

    // Retorna no formato brasileiro
    return `${dia}/${mes}/${ano} ${horas}:${minutos}:${seconds}`;
  }

  formatDate(dateString) {
    var e = dateString.split("-");
    var dateString = e[2] + "/" + e[1] + "/" + e[0];
    return dateString;
  }

  render() {
    if (this.state.loader) {
      return (
        <div class="area-loader">
          <div class="loader"></div>
        </div>
      );
    }

    if (this.state.error) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "80vh",
          }}
        >
          <img
            src={alert}
            style={{ width: "400px", top: 100 }}
            alt="imagem de alerta"
          />
          <div>
            Ops! O documento que você está procurando não foi encontrado.
          </div>
        </div>
      );
    }
    var data = this.state.data;
    var typeBiling = data.location.billing;
    var store = this.state.store;
    var client = this.state.client;
    var location = this.state.location;
    var products = this.state.products;
    var invoicingType = {
      1: "Diária",
      2: "Semanal",
      3: "Quizenal",
      4: "Mensal",
      5: "Anual",
    };
    var priceType = {
      1: "day_price",
      2: "weekly_price",
      3: "biweekly_price",
      4: "monthly_price",
      5: "annual_price",
    };

    return (
      <div class="content d-flex justify-content-center">
        <div class="col-8 mt-5">
          <div class="card">
            <div class="card-header d-flex justify-content-between align-items-center">
              <Link
                to="/lease-history"
                style={{
                  textDecoration: "none",
                  color: "#5b5b5b",
                  fontSize: 18,
                }}
              >
                <strong>
                  <FontAwesomeIcon icon={faAngleLeft} /> Voltar
                </strong>
              </Link>
              <div>
                <button className="btn btn-success " style={{ marginRight: 8 }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="20"
                    height="20"
                    viewBox="0 0 50 50"
                  >
                    <path
                      fill="#fff"
                      d="M25,2C12.318,2,2,12.318,2,25c0,3.96,1.023,7.854,2.963,11.29L2.037,46.73c-0.096,0.343-0.003,0.711,0.245,0.966 C2.473,47.893,2.733,48,3,48c0.08,0,0.161-0.01,0.24-0.029l10.896-2.699C17.463,47.058,21.21,48,25,48c12.682,0,23-10.318,23-23 S37.682,2,25,2z M36.57,33.116c-0.492,1.362-2.852,2.605-3.986,2.772c-1.018,0.149-2.306,0.213-3.72-0.231 c-0.857-0.27-1.957-0.628-3.366-1.229c-5.923-2.526-9.791-8.415-10.087-8.804C15.116,25.235,13,22.463,13,19.594 s1.525-4.28,2.067-4.864c0.542-0.584,1.181-0.73,1.575-0.73s0.787,0.005,1.132,0.021c0.363,0.018,0.85-0.137,1.329,1.001 c0.492,1.168,1.673,4.037,1.819,4.33c0.148,0.292,0.246,0.633,0.05,1.022c-0.196,0.389-0.294,0.632-0.59,0.973 s-0.62,0.76-0.886,1.022c-0.296,0.291-0.603,0.606-0.259,1.19c0.344,0.584,1.529,2.493,3.285,4.039 c2.255,1.986,4.158,2.602,4.748,2.894c0.59,0.292,0.935,0.243,1.279-0.146c0.344-0.39,1.476-1.703,1.869-2.286 s0.787-0.487,1.329-0.292c0.542,0.194,3.445,1.604,4.035,1.896c0.59,0.292,0.984,0.438,1.132,0.681 C37.062,30.587,37.062,31.755,36.57,33.116z"
                    ></path>
                  </svg>{" "}
                  Enviar contrato pelo whatsapp
                </button>

                <button
                  className="btn btn-dark"
                  style={{ marginRight: 8 }}
                  onClick={() => {
                    function Popup(data, style) {
                      var mywindow = window.open("", ".documentoViewToPrint");
                      mywindow.document.write("<html><head><title></title>");
                      /* stylesheet*/
                      mywindow.document.write("<style>" + style + "</style>");
                      mywindow.document.write("</head><body >");
                      mywindow.document.write(data);
                      mywindow.document.write("</body></html>");

                      mywindow.document.close(); // necessary for IE >= 10
                      mywindow.focus(); // necessary for IE >= 10

                      setTimeout(function () {
                        mywindow.print();
                        mywindow.close();
                      }, 100); //1 segundo

                      return true;
                    }

                    //var style = 'table{width:100%}.table, .table td{width:100%;border:1px solid #ccc;border-spacing: 0;border-collapse: collapse;padding:5px;}\n\
                    //                ';

                    var style =
                      ".bold{text-weight:bold}\n\
                              body{\n\
                                  font-size:10px; \n\
                              }\n\
                              .table-bordered, .table-bordered td { \n\
                                  border: 1px solid #ddd;\n\
                                }\n\
                                  .table-bordered, .table-bordered td { \n\
                                  border: 1px solid #ddd;\n\
                                }\n\
                                .table {\n\
                                  width: 100%;\n\
                                  max-width: 100%;\n\
                                  margin-bottom: 20px;\n\
                                  }\n\
                                table{ \n\
                                  border-spacing: 0;\n\
                                  border-collapse: collapse;\n\
                                  }\n\
                                  table td{\n\
                                  font-size:10px;\n\
                                  padding:2px;\n\
                                  }\n\
                                .txtcenter{text-align:center}  \n\
                                .noborder, .noborder td{border:0}\n\
                                .noborderOut{border:0}\n\
                              body{font-size:11px;color:#000;font-family:arial,sans-serif}\n\
                              .txtright{text-align:right} \n\
                              \n\
                              .fontsize20{font-size:20px;}\n\
                              .fontsize16{font-size:16px;}\n\
                              ";

                    Popup(this.page.current.innerHTML, style);
                  }}
                >
                  <FontAwesomeIcon icon={faPrint} /> Imprimir contrato
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.setState({ edit: this.state.location });
                  }}
                >
                  <FontAwesomeIcon icon={faPrint} /> Editar
                </button>
              </div>
            </div>
            <div class="card-body">
              <div className="documentoViewToPrint" ref={this.page}>
                <div class="table">
                  <table class="table">
                    <tbody>
                      <tr>
                        <td width="50%" class="paddingtop15">
                          <img src={logomarca} height="90px" width="auto" />
                        </td>
                        <td class="txtright fontsize16">
                          <strong>Contrato Número {location.id} </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <strong>Contrato de Locação </strong>
                  <strong>DADOS DO LOCADOR</strong>
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <td colspan="2">
                          <strong>Nome: </strong>
                          {store.name}
                        </td>
                        <td>
                          <strong>CNPJ: </strong>
                          {store.cnpj}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3">
                          <strong>Endereço: </strong>
                          {store.address}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Bairro: </strong>
                          {store.neighborhood}
                        </td>
                        <td>
                          <strong>Cidade: </strong>
                          {store.city}
                        </td>
                        <td>
                          <strong>UF: </strong>
                          {store.state}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Celular: </strong>
                          {store.mobile}
                        </td>
                        <td>
                          <strong>Fone Comercial: </strong>
                          {store.phone}
                        </td>
                        <td>
                          <strong>Email: </strong>
                          {store.email}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3">
                          <strong>Funcionário: </strong>
                          {location.seller_name}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <strong>DADOS DO LOCATÁRIO</strong>
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Cliente: </strong>
                          {client.nome != null ? client.nome : "Sem Nome"}
                        </td>
                        <td>
                          <strong>RG: </strong>
                          {client.rg !== null ? client.rg : "Sem RG"}
                        </td>

                        <td>
                          <strong>CPF / CNPJ: </strong>
                          {client.cnpj_cpf != null
                            ? client.cnpj_cpf
                            : "Sem CPF / CNPJ"}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3">
                          <strong>Data de Nascimento:&nbsp;</strong>
                          {client.data_nascimento != null
                            ? this.formatarDataBR(client.data_nascimento).split(
                                " "
                              )[0]
                            : ""}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3">
                          <strong>Endereço: </strong>
                          {client.endereco != null
                            ? client.endereco
                            : "Sem Endereço"}{" "}
                        </td>
                        <td>
                          <strong>Número:</strong>
                          {client.numero}
                        </td>
                        <td>
                          <strong>Complemento: </strong>
                          {client.complemento}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Bairro: </strong>
                          {client.bairro != null ? client.bairro : "Sem Bairro"}
                        </td>
                        <td>
                          <strong>Cidade: </strong>
                          {client.cidade != null ? client.cidade : "Sem Cidade"}
                        </td>
                        <td>
                          <strong>UF: </strong>
                          {client.uf != null ? client.uf : "Sem UF"}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Celular/Whatsapp: </strong>
                          {client.celular != null
                            ? client.celular
                            : "Sem Celular"}
                        </td>
                        <td>
                          <strong>Telefone: </strong>
                          {client.telefone != null
                            ? client.telefone
                            : "Sem Telefone"}
                        </td>
                        <td>
                          <strong>Email: </strong>
                          {client.email != null ? client.email : "Sem Email"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <strong>PERÍODO DE LOCAÇÃO</strong>
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <td>
                          <a
                            href="locacao-prevenda_view.php?idVenda=231835022"
                            taget="_blank"
                          >
                            <strong>Contrato: </strong>
                            <strong>231835022</strong>{" "}
                          </a>
                        </td>
                        <td>
                          <strong>Início: </strong>
                          <strong>
                            {this.formatarDataBR(location.start_day)}
                          </strong>{" "}
                        </td>
                        <td>
                          <strong>Até: </strong>
                          <strong>
                            {location.renew.length > 0 ? (
                              <>
                                <s> {this.formatarDataBR(location.end_day)}</s>
                              </>
                            ) : (
                              <> {this.formatarDataBR(location.end_day)}</>
                            )}
                          </strong>{" "}
                        </td>
                        {location.renew.length > 0 && (
                          <>
                            <td>
                              <strong>
                                Renovado até:{" "}
                                {this.formatarDataBR(location.renew[0].end_day)}
                              </strong>
                            </td>
                          </>
                        )}
                        <td>
                          <strong>Faturamento: </strong>
                          <strong>
                            {invoicingType[location.billing]}
                          </strong>{" "}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <td colspan="6" class="txtcenter">
                          <strong>OBJETO DA LOCAÇÃO</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Nº.</strong>
                        </td>
                        <td>
                          <strong>Qtde.</strong>
                        </td>
                        <td>
                          <strong>Cód. Barra</strong>
                        </td>
                        <td>
                          <strong>Descrição</strong>
                        </td>
                        <td>
                          <strong>Caracteristicas do Equipamento</strong>
                        </td>

                        <td class="valores">
                          <strong>Valor por Período</strong>
                        </td>
                        <td class="valores">
                          <strong>Qtde. Períodos</strong>
                        </td>
                        <td class="valores">
                          <strong>Valor Total Item</strong>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {products.map((product, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{product.quantiny}</td>
                          <td>{product.codigo_barra}</td>
                          <td>{product.name}</td>
                          <td>{product.cdp || "---"}</td>
                          <td>
                            {typeBiling > 5
                              ? "---"
                              : product[priceType[typeBiling]].toLocaleString(
                                  "pt-BR",
                                  {
                                    style: "currency",
                                    currency: "BRL",
                                  }
                                )}
                          </td>
                          <td>{location.rental_period}</td>
                          <td>
                            {(
                              location.rental_period *
                              product[priceType[typeBiling]]
                            ).toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </td>
                        </tr>
                      ))}

                      <tr>
                        <td colspan="5">
                          <strong>Valor da Multa por Dia de Atraso:</strong>
                        </td>
                        <td colspan="2" class="valores">
                          {location.fine.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </td>
                      </tr>

                      <tr>
                        <td colspan="5">
                          <strong>Valor do Desconto:</strong>
                        </td>
                        <td colspan="2" class="valores">
                          ---
                        </td>
                      </tr>

                      <tr>
                        <td colspan="5">
                          <strong>Valor do Frete:</strong>
                        </td>
                        <td colspan="2" class="valores">
                          {location.shipping_cost == null
                            ? "---"
                            : location.shipping_cost.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              })}
                        </td>
                      </tr>

                      <tr>
                        <td colspan="5">
                          <strong>Valor Total:</strong>
                        </td>
                        <td colspan="2" class="valores">
                          {location.location_value == null
                            ? "---"
                            : (
                                location.location_value + location.shipping_cost
                              ).toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              })}
                        </td>
                      </tr>

                      <tr>
                        <td colspan="5">
                          <strong>Saldo Devedor:</strong>
                        </td>
                        <td colspan="2" class="valores">
                          ---
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <strong>OBSERVAÇÃO:</strong>
                  {this.state.location.obs}
                  <br />
                  <br />
                  <div class="divClausulasContratuais">
                    <div
                      dangerouslySetInnerHTML={{ __html: this.state.contract }}
                    />
                  </div>
                  <div
                    style={{
                      margin: "0 auto",
                      wordBreak: "break-word",
                      width: "100%",
                      maxWidth: "600px",
                    }}
                  >
                    <div class="folha">
                      <br />
                      <br />
                      <br />
                      {store.city}, 25 de Dezembro de 2024
                      <br />
                      <br />
                      <br /> ____________________________________________ <br />
                      Locador
                      <br />
                      {store.name} <br />
                      CNPJ: {store.cnpj}
                      <br />
                      <br />
                      <br />
                      ____________________________________________
                      <br />
                      Locatário
                      <br />
                      {client.nome}
                      <br />
                      CPF/CNPJ: {client.cnpj_cpf}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {data.telefone_celular != undefined && (
              <>
                <div className="card-footer d-flex justify-content-end">
                  <button
                    className="btn b-wpp"
                    onClick={(e) => {
                      e.target.classList.add("load");
                      fetch(
                        `${apiUrl}/send-budget/${
                          window.location.pathname.split("/")[3]
                        }`,
                        {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                            authorization: localStorage.getItem("token"),
                          },
                          body: JSON.stringify({}),
                        }
                      )
                        .then((response) => {
                          e.target.classList.remove("load");
                          toast.success("Orçamento enviado com sucesso");
                        })
                        .catch((error) => {
                          e.target.classList.remove("load");
                          toast.error("Ocorreu um erro ao enviar o orcamento");
                        });
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#fff"
                      viewBox="0 0 448 512"
                    >
                      <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
                    </svg>
                    Enviar orçamento
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        <StaticDialog
          isOpen={Object.keys(this.state.edit).length > 0}
          title="Editar"
          onAfterClose={(result) => {
            this.setState({
              edit: {},
            });
          }}
        >
          <div className="modal-body" style={{ margin: "10px" }}>
            <TextField
              id="outlined-basic"
              label="Observação"
              variant="outlined"
              style={{ width: "100%" }}
              value={this.state.edit.obs || ""}
              onChange={(e) => {
                this.setState(({ edit }) => ({
                  edit: { ...edit, obs: e.target.value },
                }));
              }}
            />
          </div>

          <div
            style={{
              margin: "10px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <button
              className="btn btn-primary"
              onClick={(e) => {
                var { obs } = this.state.edit;
                e.target.classList.add("load");
                fetch(
                  `${apiUrl}/lease/contract/edit/${this.props.params.token}`,
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      authorization: localStorage.getItem("token"),
                    },
                    body: JSON.stringify({
                      obs: obs,
                    }),
                  }
                )
                  .then((response) => response.json())
                  .then((data) => {
                    toast.success("Observação salva com sucesso");
                    this.setState(({ location }) => ({
                      location: { ...location, obs },
                      edit: {},
                    }));
                  });
              }}
            >
              <FontAwesomeIcon icon={faSave} /> Salvar
            </button>
          </div>
        </StaticDialog>
      </div>
    );
  }
}

export default withLocation(LeaserContract);
