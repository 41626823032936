import React, { Component } from "react";
import { Link } from "react-router-dom";
import atencao from "../../img/atencao.svg";
import logo from "../../img/logo.png";
import * as XLSX from "xlsx";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { saveAs } from "file-saver";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faArrowRight,
  faBuilding,
  faClipboard,
  faCoffee,
  faEnvelope,
  faEye,
  faFileAlt,
  faFileExcel,
  faFileInvoice,
  faFilePdf,
  faFileText,
  faFlag,
  faHome,
  faIdCard,
  faMapMarkerAlt,
  faMobileAlt,
  faPen,
  faPhone,
  faPrint,
  faRefresh,
  faSave,
  faSearch,
  faTrash,
  faUser,
  faUserPlus,
  faUserTimes,
} from "@fortawesome/free-solid-svg-icons";
import { StaticDialog, useDialog } from "react-st-modal";
import InputMask from "react-input-mask";

import { toast } from "react-toastify";
import { apiUrl } from "../../comps/apiUrl";

class Finances extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      add: false,
      dados: {},
      items: [],
      currentPage: 1,
      totalItems: 0,
      itemsPerPage: 100,
      edit: {},
      trash: {},
      viewProducts: {},
      comprovante: {},
      blockUser: {},
      filter: "1",
      equipamentos: {},
      faturamento: null,
      dw: false,
    };
    this.page = React.createRef();
  }

  Generate_token(length) {
    //edit the token allowed characters
    var a =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split(
        ""
      );
    var b = [];
    for (var i = 0; i < length; i++) {
      var j = (Math.random() * (a.length - 1)).toFixed(0);
      b[i] = a[j];
    }
    return b.join("");
  }
  componentDidMount() {
    document.title = "Histórico de Finanças";
    this.fetchData(this.state.currentPage);
  }

  fetchData(page) {
    fetch(`${apiUrl}/finances?page=${page}&limit=${this.state.itemsPerPage}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
    }).then((response) => {
      if (response.status == 510) {
        localStorage.clear();
        window.location.href = "/";
        return;
      }

      response.json().then((data) => {
        const totalItems = data.totalItems; // Simulado, substitua pelo valor real do backend.
        const totalPages = Math.ceil(totalItems / this.state.itemsPerPage);

        this.setState({
          items: data.items,
          currentPage: page,
          totalPages,
          loader: false,
        });
      });
    });
  }
  Payment_type(t) {
    if (t == "boletop") {
      return (
        <span class="badge bg-danger" style={{ cursor: "pointer" }}>
          <FontAwesomeIcon icon={faFlag} /> Boleto Pendente
        </span>
      );
    } else if (t == "boletoa") {
      return (
        <span class="badge bg-success" style={{ cursor: "pointer" }}>
          <FontAwesomeIcon icon={faFlag} /> Boleto Aprovado
        </span>
      );
    } else if (t == "pix") {
      return <span class="badge bg-success">Pix</span>;
    } else if (t == "boleto") {
      return <span class="badge bg-success">Boleto</span>;
    } else if (t == "cartaod") {
      return <span class="badge bg-success">Cartão de debito</span>;
    } else if (t == "cartaoc") {
      return <span class="badge bg-success">Cartão de credito</span>;
    } else if (t == "dinheiro") {
      return <span class="badge bg-success">Dinheiro</span>;
    } else if (t == "pne") {
      return (
        <span class="badge bg-danger" style={{ cursor: "pointer" }}>
          <FontAwesomeIcon icon={faFlag} /> Pagamento na Entrega
        </span>
      );
    } else if (t == "pnd") {
      return (
        <span class="badge bg-danger" style={{ cursor: "pointer" }}>
          <FontAwesomeIcon icon={faFlag} /> Pagamento na Devoluão
        </span>
      );
    } else if (t == "boletonp") {
      return (
        <span class="badge bg-dark" style={{ cursor: "pointer" }}>
          <FontAwesomeIcon icon={faFlag} /> Boleto Não Pago
        </span>
      );
    } else if (t == "boletoe") {
      return (
        <span class="badge bg-success" style={{ cursor: "pointer" }}>
          <FontAwesomeIcon icon={faFlag} /> Boleto Emitido
        </span>
      );
    }
  }
  handlePageClick = (page) => {
    this.fetchData(page);
  };
  handleNextPage = () => {
    this.fetchData(this.state.currentPage + 1);
  };
  formatarDataBR(dataISO) {
    if (dataISO == null) {
      return "---";
    }
    // Converte a string ISO em um objeto Date
    const data = new Date(dataISO);

    // Extrai as partes da data
    const dia = String(data.getDate()).padStart(2, "0");
    const mes = String(data.getMonth() + 1).padStart(2, "0"); // Meses começam do zero
    const ano = data.getFullYear();

    // Extrai as partes do horário
    const horas = String(data.getHours()).padStart(2, "0");
    const minutos = String(data.getMinutes()).padStart(2, "0");

    // Retorna no formato brasileiro
    return `${dia}/${mes}/${ano} ${horas}:${minutos}`;
  }
  handlePreviousPage = () => {
    if (this.state.currentPage > 1) {
      this.fetchData(this.state.currentPage - 1);
    }
  };

  renderPagination() {
    const { currentPage, totalPages } = this.state;

    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          className="btn"
          key={i}
          onClick={() => this.handlePageClick(i)}
          style={{
            backgroundColor: i === currentPage ? "#007bff" : "#fff",
            color: i === currentPage ? "#fff" : "#000",
            border: "1px solid #ddd",
            cursor: "pointer",
          }}
        >
          {i}
        </button>
      );
    }

    return (
      <div>
        <button
          className="btn btn-ligth"
          onClick={() => this.handlePageClick(currentPage - 1)}
          disabled={currentPage === 1}
          style={{
            cursor: currentPage === 1 ? "not-allowed" : "pointer",
          }}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>

        {pages}

        <button
          onClick={() => this.handlePageClick(currentPage + 1)}
          className="btn btn-ligth"
          disabled={currentPage === totalPages}
          style={{
            cursor: currentPage === totalPages ? "not-allowed" : "pointer",
          }}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
    );
  }

  handleChange = (event) => {
    const { id, value } = event.target;

    // Atualizando o estado dentro do objeto `dados`
    this.setState((prevState) => ({
      dados: {
        ...prevState.dados, // Mantém os outros campos do objeto `dados`
        [id]: value, // Atualiza apenas o campo correspondente
      },
    }));
  };

  handleEdit = (event) => {
    const { id, value } = event.target;

    // Atualizando o estado dentro do objeto `dados`
    this.setState((prevState) => ({
      edit: {
        ...prevState.edit, // Mantém os outros campos do objeto `dados`
        [id]: value, // Atualiza apenas o campo correspondente
      },
    }));
  };
  formatDate(dateString) {
    var e = dateString.split("-");
    var dateString = e[2] + "/" + e[1] + "/" + e[0];
    return dateString;
  }
  handleCpfCnpjChange = (e) => {
    function cnpj(v) {
      v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
      v = v.replace(/^(\d{2})(\d)/, "$1.$2"); //Coloca ponto entre o segundo e o terceiro dígitos
      v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3"); //Coloca ponto entre o quinto e o sexto dígitos
      v = v.replace(/\.(\d{3})(\d)/, ".$1/$2"); //Coloca uma barra entre o oitavo e o nono dígitos
      v = v.replace(/(\d{4})(\d)/, "$1-$2"); //Coloca um hífen depois do bloco de quatro dígitos
      return v;
    }

    function cpf(v) {
      v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
      v = v.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
      v = v.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
      //de novo (para o segundo bloco de números)
      v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2"); //Coloca um hífen entre o terceiro e o quarto dígitos
      return v;
    }

    if (e.target.value.length > 18) return;
    var value = e.target.value;
    if (e.target.value.length <= 14) {
      this.setState((prevState) => ({
        dados: {
          ...prevState.dados, // Mantém os outros campos do objeto `dados`
          ["doc"]: cpf(value), // Atualiza apenas o campo correspondente
        },
      }));
    } else {
      this.setState((prevState) => ({
        dados: {
          ...prevState.dados, // Mantém os outros campos do objeto `dados`
          ["doc"]: cnpj(value), // Atualiza apenas o campo correspondente
        },
      }));
    }
  };

  render() {
    if (this.state.loader) {
      return (
        <div class="area-loader">
          <div class="loader"></div>
        </div>
      );
    }
    var invoicingType = {
      1: "Diária",
      2: "Semanal",
      3: "Quizenal",
      4: "Mensal",
      5: "Anual",
    };

    var priceType = {
      1: "day_price",
      2: "weekly_price",
      3: "biweekly_price",
      4: "monthly_price",
      5: "annual_price",
    };
    return (
      <>
        <div class="content">
          <div class="card">
            <div class="card-header d-flex align-items-center justify-content-between">
              <strong>Financeiro</strong>
              <div>
                <button
                  className="btn btn-success"
                  onClick={() => {
                    const rentals = this.state.items;

                    // Transformando os dados para Excel
                    const excelData = [];

                    rentals.forEach((rental) => {
                      rental.products.forEach((product) => {
                        excelData.push({
                          ID: rental.id,
                          Vendedor: rental.seller_name,
                          Cliente: rental.client_name,
                          "ID Cliente": rental.client_id,
                          "Tipo Pagamento": rental.payment_type,
                          "Valor Locação": rental.location_value,
                          "Custo Frete": rental.shipping_cost,
                          Multa: rental.fine,
                          "Código Produto": product.codigo_barra,
                          Produto: product.name,
                          Quantidade: product.quantiny,
                          "Preço Diário": product.day_price,
                          "Preço Semanal": product.weekly_price,
                          "Preço Quinzenal": product.biweekly_price,
                          "Preço Mensal": product.monthly_price,
                          "Data Início": rental.start_day,
                          "Data Fim": rental.end_day,
                        });
                      });
                    });

                    // Criar planilha
                    const worksheet = XLSX.utils.json_to_sheet(excelData);
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(
                      workbook,
                      worksheet,
                      "Aluguéis"
                    );

                    // Gerar arquivo e baixar
                    XLSX.writeFile(workbook, "alugueis.xlsx");
                  }}
                >
                  <FontAwesomeIcon icon={faFileExcel} />
                </button>
                <button
                  className="btn btn-danger"
                  style={{ marginLeft: 10 }}
                  onClick={() => {
                    const doc = new jsPDF({
                      orientation: "portrait",
                      unit: "mm",
                      format: "a4",
                      marginLeft: 0, // Remove a margem esquerda
                      marginRight: 0, // Remove a margem direita
                      marginTop: 0, // Remove a margem superior
                      marginBottom: 0, // Remove a margem inferior
                    });

                    // 2️⃣ Adiciona um título ao PDF
                    doc.setFontSize(16);

                    const data = this.state.items.map((item) => [
                      item.client_name,
                      item.payment_type,
                      invoicingType[item.billing],
                      item.location_value.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }),
                      item.shipping_cost.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }),
                      item.fine.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }),
                      item.location_value + item.shipping_cost + item.fine,
                      new Date(item.start_day).toLocaleDateString(),
                      new Date(item.end_day).toLocaleDateString(),
                    ]);

                    // 4️⃣ Cabeçalhos das colunas
                    const headers = [
                      [
                        "Cliente",
                        "Metodo de Pagamento",
                        "Faturamento",
                        "Valor da Locação",
                        "Frete",
                        "Multa",
                        "Total",
                        "Locação",
                        "Devolução",
                      ],
                    ];

                    // 5️⃣ Adiciona a tabela ao PDF
                    doc.autoTable({
                      head: headers,
                      margin: 0,
                      body: data,
                    });

                    // 6️⃣ Salva o PDF com nome "relatorio.pdf"
                    doc.save(this.Generate_token(6) + ".pdf");
                  }}
                >
                  <FontAwesomeIcon icon={faFilePdf} />
                </button>
              </div>
            </div>
            <div class="card-body">
              <div class="mb-3 d-flex justify-content-center">
                <div class="row w-50">
                  <div className="col-md-4">
                    <select
                      class="form-control"
                      onChange={(e) => {
                        this.setState({
                          filter: e.target.value,
                          faturamento: undefined,
                        });
                      }}
                    >
                      <option value="1">Data de Pagamento</option>
                      <option value="2">Metodo de Pagamento</option>
                      <option value="3">Tipo de equipamento</option>
                      <option value="4">Nome do Cliente</option>
                      <option value="5">Tipo de Faturamento</option>
                    </select>
                  </div>

                  <div className="col-md-4">
                    <input
                      type="date"
                      class="form-control"
                      onChange={(e) => {
                        this.setState({
                          start_date: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-4">
                    <input
                      type="date"
                      class="form-control"
                      onChange={(e) => {
                        this.setState({
                          end_date: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div class="mb-3 d-flex justify-content-center">
                <div class="row w-50">
                  <div className="col-md-6">
                    {this.state.filter == 2 && (
                      <>
                        <select
                          name="tpay"
                          class="form-control"
                          onChange={(e) => {
                            if (e.target.value == "todos") {
                              this.setState({
                                filter: "1",
                              });
                            }
                            this.setState({
                              faturamento: e.target.value,
                            });
                          }}
                        >
                          <option value="">Selecione</option>

                          <option value="boletop">Boleto</option>
                          <option value="cartaoc">Cartão de Crédito</option>
                          <option value="cartaod">Cartão de Débito</option>
                          <option value="dinheiro">Dinheiro</option>
                          <option value="pix">PIX</option>
                          <option value="todos">Todos</option>
                        </select>
                      </>
                    )}

                    {(this.state.filter == 3 || this.state.filter == 4) && (
                      <>
                        <input
                          type="text"
                          class="form-control"
                          onChange={(e) => {
                            this.setState({
                              faturamento: e.target.value,
                            });
                          }}
                          placeholder="Digite:"
                        />
                      </>
                    )}

                    {this.state.filter == 5 && (
                      <>
                        <select
                          class="form-control"
                          id="faturamento"
                          name="faturamento"
                          onChange={(e) => {
                            if (e.target.value == "todos") {
                              this.setState({
                                filter: "1",
                              });
                            }
                            this.setState({
                              faturamento: e.target.value,
                            });
                          }}
                          disabled=""
                        >
                          <option value="0">Selecione</option>
                          <option value="1">Diária</option>
                          <option value="2">Semanal</option>
                          <option value="3">Quinzenal</option>
                          <option value="4">Mensal</option>
                          <option value="5">Anual</option>
                          <option value="todos">Todos</option>
                        </select>
                      </>
                    )}
                  </div>

                  <div className="col-md-4">
                    {this.state.busca != undefined && (
                      <>
                        <button
                          className="btn btn-success"
                          onClick={() => {
                            this.fetchData(1);
                            this.setState({
                              busca: undefined,
                            });
                          }}
                        >
                          <FontAwesomeIcon icon={faRefresh} />
                        </button>
                      </>
                    )}
                  </div>
                  <div className="col-md-2">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        if (this.state.start_date == undefined) {
                          toast.warn("Informe a data de inicio");
                          return;
                        }

                        if (this.state.end_date == undefined) {
                          toast.warn("Informe a data de fim");
                          return;
                        }
                        if (
                          this.state.filter == 2 &&
                          this.state.faturamento == undefined
                        ) {
                          toast.warn("Selecione o metodo de pagamento");
                          return;
                        }

                        if (
                          this.state.filter == 3 &&
                          this.state.faturamento == undefined
                        ) {
                          toast.warn("Digite o nome do equipamento");
                          return;
                        }
                        if (
                          this.state.filter == 3 &&
                          this.state.faturamento == undefined
                        ) {
                          toast.warn("Digite o nome do equipamento");
                          return;
                        }

                        if (
                          this.state.filter == 4 &&
                          this.state.faturamento == undefined
                        ) {
                          toast.warn("Digite o nome do cliente");
                          return;
                        }

                        if (
                          this.state.filter == 4 &&
                          this.state.faturamento == undefined
                        ) {
                          toast.warn("Selecione o tipo de pagamento");
                          return;
                        }

                        fetch(`${apiUrl}/search-finances`, {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: localStorage.getItem("token"),
                          },
                          body: JSON.stringify({
                            filter: this.state.filter,
                            start_date: this.state.start_date,
                            end_date: this.state.end_date,
                            faturamento: this.state.faturamento,
                          }),
                        }).then((response) => {
                          response.json().then((data) => {
                            this.setState({
                              busca: true,
                              items: data,
                            });
                          });
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faSearch} /> Buscar
                    </button>
                  </div>
                </div>
              </div>

              {this.state.items.length > 0 ? (
                <>
                  <div className="documentoViewToPrint" ref={this.page}>
                    <table class="table table-striped table-bordered arvore-l">
                      <thead class="thead-dark">
                        <tr>
                          <th>ID do Cliente</th>

                          <th>Cliente</th>
                          <th>Tipo de Operação</th>
                          <th>Metodo de Pagamento</th>
                          <th>Equipamentos</th>
                          <th>Faturamento</th>
                          <th>Valor Locação</th>
                          <th>Frete</th>
                          <th>Multa</th>
                          <th>Total</th>
                          <th>Locação</th>
                          <th>Devolução</th>
                          <th>Data de Pagamento</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.items.map((item, index) => (
                          <>
                            <tr>
                              <td>{item.id}</td>

                              <td>
                                <Link
                                  to={`/cadastro-clientes/view/${item.client_id}`}
                                >
                                  {item.client_name}
                                </Link>
                              </td>
                              <td>
                                {item.tdo == undefined
                                  ? "---"
                                  : item.tdo == 1
                                  ? "Locação"
                                  : "Renovação"}
                              </td>
                              <td>{this.Payment_type(item.payment_type)} </td>
                              <td>
                                <button
                                  className="btn btn-primary"
                                  onClick={() => {
                                    this.setState({
                                      equipamentos: item.products,
                                    });
                                  }}
                                >
                                  <FontAwesomeIcon icon={faEye} />
                                </button>
                              </td>

                              <td>{invoicingType[item.billing]}</td>
                              <td>
                                {item.location_value == null
                                  ? "---"
                                  : item.location_value.toLocaleString(
                                      "pt-BR",
                                      {
                                        style: "currency",
                                        currency: "BRL",
                                      }
                                    )}
                              </td>

                              <td>
                                {item.shipping_cost != null
                                  ? item.shipping_cost.toLocaleString("pt-BR", {
                                      style: "currency",
                                      currency: "BRL",
                                    })
                                  : "---"}
                              </td>
                              <td>
                                {item.fine != null
                                  ? item.fine.toLocaleString("pt-BR", {
                                      style: "currency",
                                      currency: "BRL",
                                    })
                                  : "---"}
                              </td>
                              <td>
                                {(
                                  item.location_value +
                                  item.shipping_cost +
                                  item.fine
                                ).toLocaleString("pt-BR", {
                                  style: "currency",
                                  currency: "BRL",
                                })}
                              </td>
                              <td>{this.formatarDataBR(item.start_day)}</td>
                              <td>
                                {item.return_day == null
                                  ? "---"
                                  : this.formatarDataBR(item.return_day)}
                              </td>
                              <td>
                                {item.payment_a == null
                                  ? "---"
                                  : this.formatarDataBR(item.payment_a)}
                              </td>
                            </tr>
                          </>
                        ))}
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>
                            {this.state.items
                              .reduce((total, product) => {
                                return total + product.location_value;
                              }, 0)
                              .toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              })}
                          </td>
                          <td>
                            {this.state.items
                              .reduce((total, product) => {
                                return total + product.shipping_cost;
                              }, 0)
                              .toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              })}
                          </td>
                          <td>
                            {this.state.items
                              .reduce((total, product) => {
                                return total + product.fine;
                              }, 0)
                              .toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              })}
                          </td>
                          <td>
                            {this.state.items
                              .reduce((total, product) => {
                                return (
                                  total +
                                  product.location_value +
                                  product.shipping_cost
                                );
                              }, 0)
                              .toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              })}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <>Não há resultados</>
              )}
              <div
                className="card-footer"
                style={{ display: "flex", justifyContent: "center" }}
              >
                {" "}
                {this.renderPagination()}
              </div>
            </div>

            <div
              className="card-footer"
              style={{ display: "flex", justifyContent: "center" }}
            ></div>
          </div>
        </div>
        <StaticDialog
          isOpen={Object.keys(this.state.equipamentos).length > 0}
          title="Equipamentos"
          onAfterClose={(result) => {
            this.setState({ equipamentos: {} });
          }}
        >
          <div className="content">
            <table class="table table-striped table-bordered arvore-l">
              <thead class="thead-dark">
                <tr>
                  <th>Nome</th>
                  <th>Quantidade</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(this.state.equipamentos).length > 0 &&
                  this.state.equipamentos.map((item, index) => (
                    <>
                      <tr>
                        <td>{item.name}</td>
                        <td>{item.quantiny}</td>
                      </tr>
                    </>
                  ))}
              </tbody>
            </table>
          </div>
        </StaticDialog>
      </>
    );
  }
}

export default Finances;
