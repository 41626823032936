import React, { Component } from "react";

import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faCalendar,
  faCheck,
  faFile,
  faFileAlt,
  faFlag,
  faPen,
  faPray,
  faPrint,
  faSearch,
  faTrash,
  faTruck,
  faUndo,
  fawhatsapp,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import alert from "../../img/alert.svg";
import { StaticDialog } from "react-st-modal";
import { apiUrl } from "../../comps/apiUrl";
class LeasesDeleted extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      loader: true,
      data: [],
      image: null,
    };
  }

  componentDidMount() {
    document.title = "Locações Deletadas";
    fetch(`${apiUrl}/leasers/deleted`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          this.setState({
            loader: false,
            error: true,
          });
        }
        this.setState({
          data: data,

          loader: false,
        });
      })
      .catch((error) => {
        toast.error("Ocorreu um erro ao carregar as maquinas");
      });
  }

  formatDate(dateString) {
    var e = dateString.split("-");
    var dateString = e[2] + "/" + e[1] + "/" + e[0];
    return dateString;
  }
  Payment_type(t) {
    if (t == "boletop") {
      return (
        <span class="badge bg-danger" style={{ cursor: "pointer" }}>
          <FontAwesomeIcon icon={faFlag} /> Boleto Pendente
        </span>
      );
    } else if (t == "boletoa") {
      return (
        <span class="badge bg-success" style={{ cursor: "pointer" }}>
          <FontAwesomeIcon icon={faFlag} /> Boleto Aprovado
        </span>
      );
    } else if (t == "pix") {
      return <span class="badge bg-success">Pix</span>;
    } else if (t == "boleto") {
      return <span class="badge bg-success">Boleto</span>;
    } else if (t == "cartaod") {
      return <span class="badge bg-success">Cartão de debito</span>;
    } else if (t == "cartaoc") {
      return <span class="badge bg-success">Cartão de credito</span>;
    } else if (t == "dinheiro") {
      return <span class="badge bg-success">Dinheiro</span>;
    } else if (t == "pne") {
      return (
        <span class="badge bg-danger" style={{ cursor: "pointer" }}>
          <FontAwesomeIcon icon={faFlag} /> Pagamento na Entrega
        </span>
      );
    } else if (t == "pnd") {
      return (
        <span class="badge bg-danger" style={{ cursor: "pointer" }}>
          <FontAwesomeIcon icon={faFlag} /> Pagamento na Devoluão
        </span>
      );
    } else if (t == "boletonp") {
      return (
        <span class="badge bg-dark" style={{ cursor: "pointer" }}>
          <FontAwesomeIcon icon={faFlag} /> Boleto Não Pago
        </span>
      );
    }
  }
  formatarDataBR(dataISO) {
    // Converte a string ISO em um objeto Date
    const data = new Date(dataISO);

    // Extrai as partes da data
    const dia = String(data.getDate()).padStart(2, "0");
    const mes = String(data.getMonth() + 1).padStart(2, "0"); // Meses começam do zero
    const ano = data.getFullYear();

    // Extrai as partes do horário
    const horas = String(data.getHours()).padStart(2, "0");
    const minutos = String(data.getMinutes()).padStart(2, "0");

    // Retorna no formato brasileiro
    return `${dia}/${mes}/${ano} ${horas}:${minutos}`;
  }

  render() {
    if (this.state.loader) {
      return (
        <div class="area-loader">
          <div class="loader"></div>
        </div>
      );
    }

    var data = this.state.data;

    var typeBiling = data.billing;
    var invoicingType = {
      1: "Diária",
      2: "Semanal",
      3: "Quizenal",
      4: "Mensal",
      5: "Anual",
    };
    var priceType = {
      1: "day_price",
      2: "weekly_price",
      3: "biweekly_price",
      4: "monthly_price",
      5: "annual_price",
    };

    return (
      <div class="content">
        <div class="card">
          <div class="card-header d-flex align-items-center justify-content-between">
            <strong>Locações Deletadas</strong>
            {this.state.master != undefined && (
              <>
                <Link to="/leasers/deleted">
                  Histórico de Locações Deletadas
                </Link>
              </>
            )}
          </div>
          <div class="card-body">
            <div>
              {data.length > 0 ? (
                <>
                  <table className="table table-striped table-bordered arvore-l">
                    <thead className="thead-dark">
                      <tr>
                        <th>Tipo de Operação</th>
                        <th>Funcionário(a)</th>
                        <th>Cliente</th>
                        <th>Faturamento da Locação</th>
                        <th>Valor Locação</th>
                        <th>Data da Locação</th>
                        <th>Data de Vencimento</th>
                        <th>Status de Vencimento</th>
                        <th>Data de Pagamento</th>
                        <th>Metodo de Pagamento</th>
                        <th>Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, index) => (
                        <tr key={item.id}>
                          <td>{item.tdo === 1 ? "Locação" : "Renovação"}</td>
                          <td>{item.seller_name}</td>
                          <td>
                            <Link
                              to={`/cadastro-clientes/view/${item.client_id}`}
                              style={{ textDecoration: "none" }}
                            >
                              {item.client_name}
                            </Link>
                          </td>
                          <td>{invoicingType[item.billing]}</td>
                          <td>
                            {item.location_value.toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </td>
                          <td>{this.formatarDataBR(item.start_day)}</td>
                          <td>{this.formatarDataBR(item.end_day)}</td>
                          <td>
                            <span
                              className="badge"
                              style={{
                                background: item.order.bg,
                                color: item.order.color,
                              }}
                            >
                              {item.order.message}
                            </span>
                          </td>
                          <td>
                            {item.payment_a
                              ? this.formatarDataBR(item.payment_a)
                              : "---"}
                          </td>
                          <td>
                            {item.payment_type == "boletoe" && (
                              <>
                                {" "}
                                <span
                                  class="badge"
                                  onClick={() => {
                                    this.setState({ boletoe: item });
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    background: "#ae2be2",
                                  }}
                                >
                                  <FontAwesomeIcon icon={faFlag} /> Boleto
                                  Emitido
                                </span>
                              </>
                            )}

                            {item.payment_type == "pnd" ||
                            item.payment_type == "pne" ? (
                              <div
                                onClick={(e) => {
                                  this.setState({ pay: item });
                                }}
                              >
                                {this.Payment_type(item.payment_type)}
                              </div>
                            ) : item.payment_type == "boletop" ? (
                              <div
                                onClick={(e) => {
                                  this.setState({ boletop: item });
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <span class="badge bg-danger">
                                  Boleto Pendente
                                </span>
                              </div>
                            ) : (
                              this.Payment_type(item.payment_type)
                            )}
                          </td>

                          <td style={{ position: "relative" }}>
                            <button
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.target.classList.add("load");
                                fetch(`${apiUrl}/lease-history/restore/`, {
                                  method: "POST",
                                  headers: {
                                    "Content-Type": "application/json",
                                    authorization:
                                      localStorage.getItem("token"),
                                  },
                                  body: JSON.stringify({ id: item.id }),
                                }).then((response) => {
                                  e.target.classList.remove("load");
                                  if (response.status == 200) {
                                    this.setState((prevState) => ({
                                      data: prevState.data.filter(
                                        (i) => i.id !== item.id
                                      ),
                                    }));
                                  }
                                });
                              }}
                            >
                              <FontAwesomeIcon icon={faUndo} /> Desfazer
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              ) : (
                <>Não há resultados</>
              )}
            </div>
          </div>

          <div
            className="card-footer"
            style={{ display: "flex", justifyContent: "center" }}
          ></div>
        </div>
      </div>
    );
  }
}

export default LeasesDeleted;
