import React, { Component } from "react";
import { apiUrl } from "../../../comps/apiUrl";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faFile,
  faFileAlt,
  faPen,
  faPrint,
  fawhatsapp,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import alert from "../../../img/alert.svg";
import logomarca from "../../../img/logomarca.jpg";
import withLocation from "../../../comps/withLocation";
class LeaserContractPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      loader: true,
      data: [],
      store: {},
      client: {},
      location: {},
      products: {},
    };
  }

  componentDidMount() {
    document.title = "Visualizar locação";
    fetch(`${apiUrl}/lease/contract/${this.props.params.token}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          this.setState({
            loader: false,
            error: true,
          });
        }
        this.setState({
          data: data,
          store: data.store,
          client: data.client,
          location: data.location,
          products: data.products,
          loader: false,
        });
      })
      .catch((error) => {
        toast.error("Ocorreu um erro ao carregar as maquinas");
      });
  }

  formatarDataBR(dataISO) {
    // Converte a string ISO em um objeto Date
    const data = new Date(dataISO);

    // Extrai as partes da data
    const dia = String(data.getDate()).padStart(2, "0");
    const mes = String(data.getMonth() + 1).padStart(2, "0"); // Meses começam do zero
    const ano = data.getFullYear();

    // Extrai as partes do horário
    const horas = String(data.getHours()).padStart(2, "0");
    const minutos = String(data.getMinutes()).padStart(2, "0");
    const seconds = String(data.getSeconds()).padStart(2, "0");

    // Retorna no formato brasileiro
    return `${dia}/${mes}/${ano} ${horas}:${minutos}:${seconds}`;
  }

  formatDate(dateString) {
    var e = dateString.split("-");
    var dateString = e[2] + "/" + e[1] + "/" + e[0];
    return dateString;
  }

  render() {
    if (this.state.loader) {
      return (
        <div class="area-loader">
          <div class="loader"></div>
        </div>
      );
    }

    if (this.state.error) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "80vh",
          }}
        >
          <img
            src={alert}
            style={{ width: "400px", top: 100 }}
            alt="imagem de alerta"
          />
          <div>
            Ops! O documento que você está procurando não foi encontrado.
          </div>
        </div>
      );
    }
    var data = this.state.data;
    var typeBiling = data.location.billing;
    var store = this.state.store;
    var client = this.state.client;
    var location = this.state.location;
    var products = this.state.products;
    var invoicingType = {
      1: "Diária",
      2: "Semanal",
      3: "Quizenal",
      4: "Mensal",
      5: "Anual",
    };
    var priceType = {
      1: "day_price",
      2: "weekly_price",
      3: "biweekly_price",
      4: "monthly_price",
      5: "annual_price",
    };

    return (
      <div class="content d-flex justify-content-center">
        <div class="col-8 mt-5">
          <div class="card">
            <div class="card-header d-flex justify-content-between align-items-center">
              <Link
                to="/lease-history"
                style={{
                  textDecoration: "none",
                  color: "#5b5b5b",
                  fontSize: 18,
                }}
              >
                <strong>
                  {" "}
                  <FontAwesomeIcon icon={faAngleLeft} /> Voltar
                </strong>
              </Link>
              <div>
                <button className="btn btn-dark" style={{ marginRight: 8 }}>
                  <FontAwesomeIcon icon={faPrint} /> Imprimir contrato
                </button>
                <button className="btn btn-dark">
                  <FontAwesomeIcon icon={faPrint} /> Imprimir contrato (Sem
                  valores)
                </button>
              </div>
            </div>
            <div class="card-body">
              <div className="col-md-offset-2 col-md-8 documentoViewToPrint">
                <div class="table">
                  <table class="table">
                    <tbody>
                      <tr>
                        <td width="50%" class="paddingtop15">
                          <img src={logomarca} height="90px" width="auto" />
                        </td>
                        <td class="txtright fontsize16">
                          <strong>Contrato Número {location.id} </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <strong>Contrato de Locação </strong>
                  <strong>DADOS DO LOCADOR</strong>
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <td colspan="2">
                          <strong>Nome: </strong>
                          {store.name}
                        </td>
                        <td>
                          <strong>CNPJ: </strong>
                          {store.cnpj}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3">
                          <strong>Endereço: </strong>
                          {store.address}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Bairro: </strong>
                          {store.neighborhood}
                        </td>
                        <td>
                          <strong>Cidade: </strong>
                          {store.city}
                        </td>
                        <td>
                          <strong>UF: </strong>
                          {store.state}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Celular: </strong>
                          {store.mobile}
                        </td>
                        <td>
                          <strong>Fone Comercial: </strong>
                          {store.phone}
                        </td>
                        <td>
                          <strong>Email: </strong>
                          {store.email}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3">
                          <strong>Funcionário: </strong>
                          {location.seller_name}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <strong>DADOS DO LOCATÁRIO</strong>
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Cliente: </strong>
                          {client.nome != null ? client.nome : "Sem Nome"}
                        </td>
                        <td>
                          <strong>RG: </strong>
                          {client.rg !== null ? client.rg : "Sem RG"}
                        </td>

                        <td>
                          <strong>CPF / CNPJ: </strong>
                          {client.cnpj_cpf != null
                            ? client.cnpj_cpf
                            : "Sem CPF / CNPJ"}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3">
                          <strong>Data de Nascimento:&nbsp;</strong>
                          {client.data_nascimento != null
                            ? this.formatarDataBR(client.data_nascimento).split(
                                " "
                              )[0]
                            : ""}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3">
                          <strong>Endereço: </strong>
                          {client.endereco != null
                            ? client.endereco
                            : "Sem Endereço"}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Bairro: </strong>
                          {client.bairro != null ? client.bairro : "Sem Bairro"}
                        </td>
                        <td>
                          <strong>Cidade: </strong>
                          {client.cidade != null ? client.cidade : "Sem Cidade"}
                        </td>
                        <td>
                          <strong>UF: </strong>
                          {client.uf != null ? client.uf : "Sem UF"}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Celular/Whatsapp: </strong>
                          {client.celular != null
                            ? client.celular
                            : "Sem Celular"}
                        </td>
                        <td>
                          <strong>Telefone: </strong>
                          {client.telefone != null
                            ? client.telefone
                            : "Sem Telefone"}
                        </td>
                        <td>
                          <strong>Email: </strong>
                          {client.email != null ? client.email : "Sem Email"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <strong>PERÍODO DE LOCAÇÃO</strong>
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <td>
                          <a
                            href="locacao-prevenda_view.php?idVenda=231835022"
                            taget="_blank"
                          >
                            <strong>Contrato: </strong>
                            <strong>231835022</strong>{" "}
                          </a>
                        </td>
                        <td>
                          <strong>Início: </strong>
                          <strong>
                            {this.formatarDataBR(location.start_day)}
                          </strong>{" "}
                        </td>
                        <td>
                          <strong>Até: </strong>
                          <strong>
                            {this.formatarDataBR(location.end_day)}
                          </strong>{" "}
                        </td>
                        <td>
                          <strong>Faturamento: </strong>
                          <strong>
                            {invoicingType[location.billing]}
                          </strong>{" "}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <td colspan="6" class="txtcenter">
                          <strong>OBJETO DA LOCAÇÃO</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Nº.</strong>
                        </td>
                        <td>
                          <strong>Qtde.</strong>
                        </td>
                        <td>
                          <strong>Cód. Barra</strong>
                        </td>
                        <td>
                          <strong>Descrição</strong>
                        </td>
                        <td>
                          <strong>Caracteristicas do Equipamento</strong>
                        </td>

                        <td class="valores">
                          <strong>Valor por Período</strong>
                        </td>
                        <td class="valores">
                          <strong>Qtde. Períodos</strong>
                        </td>
                        <td class="valores">
                          <strong>Valor Total Item</strong>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {products.map((product, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{product.quantiny}</td>
                          <td>{product.codigo_barra}</td>
                          <td>{product.name}</td>
                          <td>{product.cdp || "---"}</td>
                          <td>
                            {product[priceType[typeBiling]].toLocaleString(
                              "pt-BR",
                              {
                                style: "currency",
                                currency: "BRL",
                              }
                            )}
                          </td>
                          <td>{location.rental_period}</td>
                          <td>
                            {(
                              location.rental_period *
                              product[priceType[typeBiling]]
                            ).toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </td>
                        </tr>
                      ))}

                      <tr>
                        <td colspan="5">
                          <strong>Valor da Multa por Dia de Atraso:</strong>
                        </td>
                        <td colspan="2" class="valores">
                          {location.fine.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </td>
                      </tr>

                      <tr>
                        <td colspan="5">
                          <strong>Valor do Desconto:</strong>
                        </td>
                        <td colspan="2" class="valores">
                          ---
                        </td>
                      </tr>

                      <tr>
                        <td colspan="5">
                          <strong>Valor do Frete:</strong>
                        </td>
                        <td colspan="2" class="valores">
                          {location.shipping_cost.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </td>
                      </tr>

                      <tr>
                        <td colspan="5">
                          <strong>Valor Total:</strong>
                        </td>
                        <td colspan="2" class="valores">
                          {(
                            location.location_value + location.shipping_cost
                          ).toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </td>
                      </tr>

                      <tr>
                        <td colspan="5">
                          <strong>Saldo Devedor:</strong>
                        </td>
                        <td colspan="2" class="valores">
                          ---
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <strong>OBSERVAÇÃO:</strong>
                  RETIRADO EM NOSSA SEDE ( SERÁ DEVOLVIDA NA LOCALIZAÇÃO ENVIADA
                  )
                  <br />
                  <br />
                  <strong>CLÁUSULAS CONTRATUAIS</strong>
                  <div class="divClausulasContratuais">
                    <p>
                      <strong>
                        Parágrafo único: equipamento devolvido sujo será cobrado
                        taxa de limpeza no valor de 100,00 reais.&nbsp;
                      </strong>
                    </p>
                    <p>
                      <strong>Parágrafo único</strong>: o bem móvel descrito
                      nesta cláusula, alugado pelo presente instrumento ao
                      LOCATÁRIO, será utilizado para alisamento de concreto
                      umido.
                    </p>
                    <p>
                      <strong>
                        Parágrafo único: O contrato só será renovado por igual
                        periodo e valor, não será aceito somente a difença do
                        valor.
                      </strong>
                    </p>
                    <p>
                      <strong>
                        <u>Parágrafo primeiro</u>
                      </strong>
                      : findo o prazo da locação, o LOCATÁRIO devera fazer a
                      devolução do equipamento em perfeitas condições,
                      respondendo pelos danos decorrentes de mau uso, descritos
                      no parágrafo único da CLÁUSULA SEXTA.
                    </p>
                    <p>
                      <strong>
                        <u>Parágrafo segundo</u>
                      </strong>
                      : a locação poderá ser renovada por prazo determinado,
                      desde que o LOCATÁRIO realize o pagamento adiantado do
                      valor da locação.
                    </p>
                    <p>
                      <strong>
                        <u>Parágrafo terceiro</u>:
                      </strong>{" "}
                      a omissão do LOCATÁRIO quanto a renovação do prazo de
                      locação, será interpretada como desinteresse na locação,
                      podendo assim o LOCADOR remover o equipamento
                      imediatamente após o vencimento do prazo da locação
                      descrito acima.
                    </p>
                    <p>
                      <span style={{ background: "#ffcc00" }}>
                        Pagamento e condições.
                      </span>
                    </p>
                    <p>
                      <strong>
                        <u>Parágrafo primeiro</u>:
                      </strong>{" "}
                      o pagamento da quantia acima descrita será realizado no
                      ato da assinatura deste instrumento, de forma adiantada,
                      ou seja, a liberação do equipamento objeto da locação
                      somente será feita após a realização do pagamento pelo
                      LOCATÁRIO.
                    </p>
                    <p>
                      <strong>
                        <u>Parágrafo segundo</u>
                      </strong>
                      : de igual modo, como condição para renovação da locação,
                      fica o LOCATÁRIO obrigado a realizar o pagamento do valor
                      descrito nesta cláusula. Fica considerado renovado o
                      contrato de locação no momento do recebimento do valor de
                      locação, pelo LOCADOR.
                    </p>
                    <p>
                      <strong>
                        <u>Parágrafo terceiro</u>
                      </strong>
                      : o LOCATÁRIO obriga-se pelos pagamentos do aluguel
                      estipulado neste contrato até o final do prazo ajustado,
                      na forma do parágrafo único do artigo nº 1.193 do Código
                      Civil Brasileiro.
                    </p>
                    <p>
                      <strong>
                        <u>Parágrafo quarto</u>:
                      </strong>{" "}
                      As faturas não pagas até o vencimento serão acrescidas da
                      variação do IGP-M, aplicada pelos dias de atraso,
                      cominada, também, multa de 2% e juros de mora de 1% ao mês
                      ou fração, sem prejuízo das demais sanções aplicáveis,
                      dentre as quais o desligamento do equipamento, a suspensão
                      da Assistência Técnica ou a rescisão deste contrato com a
                      remoção do equipamento.
                    </p>
                    <p>
                      <strong>CLÁUSULA QUARTA – REAJUSTE</strong>: O valor de
                      locação estipulado neste contrato será reajustado com base
                      na variação do IGP-M (Índice Geral de Preços de Mercado),
                      variação esta a ser aplicada sempre na menor periodicidade
                      admitida em lei, em qualquer época de vigência deste
                      contrato. Na hipótese de suspensão, extinção ou vedação do
                      uso do IGP-M como índice de atualização de preços, fica
                      desde já eleito o índice que oficialmente vier a
                      substituí-lo.
                    </p>
                    <p>
                      <strong>CLÁUSULA QUINTA – OBRIGAÇÕES DO LOCADOR</strong>:
                      são obrigações do LOCADOR:
                    </p>
                    <ol>
                      <li>
                        desde que cumprido pelo LOCATÁRIO o disposto na CLÁUSULA
                        TERCEIRA, se obriga a entregar o equipamento ao
                        LOCATÁRIO em perfeitas condições de funcionamento, sem
                        qualquer ônus para a LOCATÁRIO, até o final do presente
                        contrato de locação, prorrogado ou não.
                      </li>
                    </ol>
                    <ol>
                      <li>
                        a realização do transporte de entrega e remoção será
                        cobrado taxa de 1,00 real por km rodado, taxa minima
                        10,00, local definido pelo LOCATÁRIO.
                      </li>
                    </ol>
                    <ol>
                      <li>
                        a realização dos serviços técnicos e manutenção e reparo
                        do equipamento, substituindo, também por sua conta,
                        todas as peças que se fizerem necessárias em decorrência
                        do uso normal. Esses serviços serão prestados
                        exclusivamente no Território Nacional e durante o
                        horário normal de expediente comercial da
                        Locadora.&nbsp;Não se inclui neste item, componentes
                        danificados pelo mau uso do LOCATÁRIO.{" "}
                        <u>Serão considerados itens de desgaste natural</u>:
                        rolamentos e lubrificação, Quaisquer itens que não sejam
                        de desgaste natural, serão substituídos pelo LOCADOR e
                        cobrados do LOCATÁRIO na respectiva fatura.
                      </li>
                    </ol>
                    <p>
                      <strong>CLÁUSULA SEXTA – OBRIGAÇÕES DO LOCATÁRIO:</strong>{" "}
                      são obrigações do LOCATÁRIO:
                    </p>
                    <p>
                      &nbsp; &nbsp; &nbsp; 1. expressamente proibido usar o
                      equipamento em piso já seco. issso causará desgaste
                      excessivo das pás e disco.
                    </p>
                    <ol>
                      <li>
                        usar o equipamento corretamente e não sublocar, ceder
                        nem transferir a locação, total ou parcial.
                      </li>
                    </ol>
                    <ol>
                      <li>
                        realizar pontualmente o pagamento do valor referente a
                        locação, nos termos da CLÁUSULA TERCEIRA.
                      </li>
                    </ol>
                    <p>
                      &nbsp; &nbsp; &nbsp; 1.não mudar o equipamento do local no
                      qual foi entregue pelo LOCADOR, salvo se autorizado
                      expressamente pelo LOCADOR. Caso sautorizado&nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;pelo LOCADOR a mudança
                      de local, as despesas decorrentes dessas mudanças de local
                      são de responsabilidade do LOCATÁRIO.
                    </p>
                    <ol>
                      <li>
                        não modificar o equipamento e/ou permitir que terceiros
                        não autorizados pelo LOCADOR façam qualquer tipo de
                        interferência nas partes e componentes internos do
                        mesmo.
                      </li>
                    </ol>
                    <ol>
                      <li>
                        comunicar o LOCADOR de qualquer violação por terceiros
                        de qualquer dos seus direitos em relação ao equipamento.
                      </li>
                    </ol>
                    <ol>
                      <li>
                        permitir o acesso do LOCADOR ou autorizado desse para a
                        remoção ou demais serviços relacionados ao equipamento
                        objeto deste instrumento, nas hipóteses cabíveis.
                      </li>
                    </ol>
                    <ol>
                      <li>
                        zelar pelo equipamento objeto deste instrumento, devendo
                        ao final do prazo deste contrato, restituí-lo nas mesmas
                        condições em que estavam quando recebidas do LOCADOR,
                        excetuados os itens de desgaste natural descritos no
                        item “c” da CLÁUSULA QUINTA.
                      </li>
                    </ol>
                    <p>
                      <strong>
                        <u>Parágrafo único</u>
                      </strong>
                      : o LOCATÁRIO fica obrigado a ressarcir ao LOCADOR o valor
                      correspondente as despesas pagas por esse último com o
                      reparo dos equipamentos, reparos necessários em virtude de
                      mau uso, a saber: deformação do chassi, quebra de motor,
                      quebra de partida retrátil, falta de limpeza e quebra de
                      qualquer outro componente não descrito no item “c” da
                      CLÁUSULA QUINTA.
                    </p>
                    <p>
                      <strong>CLÁUSULA SÉTIMA</strong>: o LOCADOR oferece plena
                      garantia do perfeito funcionamento do equipamento, quando
                      da respectiva instalação, obedecidas as especificações
                      técnicas, podendo o equipamento, objeto do presente
                      contrato, ser previamente revisado, dentro dos mais
                      rigorosos padrões técnicos e de controle de qualidade.
                    </p>
                    <p>
                      <strong>CLÁUSULA OITAVA</strong>: o LOCADOR entregará o
                      equipamento no local indicado pelo LOCATÁRIO, em perfeitas
                      condições de servir ao uso a que se destina.
                    </p>
                    <p>
                      <strong>CLÁUSULA NONA – PENALIDADES</strong>: Sem prejuízo
                      dos acréscimos moratórios estabelecidos no item acima,
                      caso o LOCATÁRIO não cumpra as obrigações deste contrato,
                      será cominada a multa equivalente ao valor do equipamento
                      alugado, no importe de R$ 8.600,00 (oito mil e seiscentos
                      reais) somado a custas, despesas e honorários advocatícios
                      no importe de 20% do valor para o caso de cobrança
                      judicial, podendo o LOCADOR considerar rescindido o
                      presente contrato.
                    </p>
                    <p>
                      <strong>CLÁUSULA DÉCIMA:</strong> fica estabelecido entre
                      as partes que na infração de qualquer das cláusulas
                      contratuais por parte do LOCATÁRIO, o LOCADOR poderá, além
                      de rescindir este contrato, como previsto acima, exigir e
                      obter imediata devolução do equipamento, cabendo-lhe
                      inclusive, na via judicial, a reintegração de posse do bem
                      móvel.
                    </p>
                    <p>
                      <strong>CLÁUSULA DÉCIMA PRIMEIRA:</strong> &nbsp;A recusa
                      da devolução do equipamento ou o dano nele produzido
                      obriga o LOCATÁRIO ao ressarcimento pelos danos e lucros
                      cessantes, estes pelo período em que o equipamento deixar
                      de ser utilizado pelo LOCADOR, incluindo o registro de
                      ocorrência perante a Polícia Civil, para que sejam
                      promovidas todas as medidas cabíveis, judiciais ou
                      administrativas.
                    </p>
                    <p>
                      <strong>CLÁUSULA DÉCIMA SEGUNDA:</strong> &nbsp;o LOCADOR
                      poderá remover o equipamento objeto da locação e
                      considerar o presente instrumento rescindido em caso de
                      conhecimento de falência ou insolvência do LOCATÁRIO, no
                      caso desta ser pessoa jurídica.
                    </p>
                    <p>
                      <strong>CLÁUSULA DÉCIMA TERCEIRA:</strong> A infração, por
                      qualquer das partes, das obrigações assumidas no presente
                      contrato dará à outra o direito de rescindi-lo,
                      independentemente de intimação judicial ou extrajudicial,
                      bastando, para isso, aviso por escrito, com prazo de 30
                      (trinta) dias corridos, contados da inadimplência.
                    </p>
                    <p>
                      <strong>CLÁUSULA DÉCIMA QUARTA – FORO</strong>: Fica
                      eleito o Foro Central da Comarca da Região Metropolitana
                      de Curitiba, Estado do Paraná, para dirimir quaisquer
                      questões oriundas deste contrato, renunciando as partes a
                      qualquer outro, por mais privilegiado que seja.
                    </p>
                    <p>
                      E por estarem de pleno e comum acordo com todas as
                      cláusulas, firmam o presente instrumento, por si e
                      eventuais sucessores, em duas vias de igual teor.
                    </p>
                    <p>&nbsp;</p>{" "}
                  </div>
                  <div
                    style={{
                      margin: "0 auto",
                      wordBreak: "break-word",
                      width: "100%",
                      maxWidth: "600px",
                    }}
                  >
                    <div class="folha">
                      <br />
                      <br />
                      <br />
                      {store.city}, 25 de Dezembro de 2024
                      <br />
                      <br />
                      <br /> ____________________________________________ <br />
                      Locador
                      <br />
                      {store.name} <br />
                      CNPJ: {store.cnpj}
                      <br />
                      <br />
                      <br />
                      ____________________________________________
                      <br />
                      Locatário
                      <br />
                      {client.nome}
                      <br />
                      CPF/CNPJ: {client.cnpj_cpf}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {data.telefone_celular != undefined && (
              <>
                <div className="card-footer d-flex justify-content-end">
                  <button
                    className="btn b-wpp"
                    onClick={(e) => {
                      e.target.classList.add("load");
                      fetch(
                        `${apiUrl}/send-budget/${
                          window.location.pathname.split("/")[3]
                        }`,
                        {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                            authorization: localStorage.getItem("token"),
                          },
                          body: JSON.stringify({}),
                        }
                      )
                        .then((response) => {
                          e.target.classList.remove("load");
                          toast.success("Orçamento enviado com sucesso");
                        })
                        .catch((error) => {
                          e.target.classList.remove("load");
                          toast.error("Ocorreu um erro ao enviar o orcamento");
                        });
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#fff"
                      viewBox="0 0 448 512"
                    >
                      <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
                    </svg>
                    Enviar orçamento
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withLocation(LeaserContractPrint);
