import React, { Component } from "react";
import { Link } from "react-router-dom";
import atencao from "../../../img/atencao.svg";
import logo from "../../../img/logo.png";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faClipboard,
  faCoffee,
  faEnvelope,
  faEye,
  faFileAlt,
  faFileExcel,
  faFileInvoice,
  faFileText,
  faFlag,
  faHome,
  faIdCard,
  faMapMarkerAlt,
  faMobileAlt,
  faPen,
  faPhone,
  faPrint,
  faRefresh,
  faSave,
  faSearch,
  faTrash,
  faUser,
  faUserPlus,
  faUserTimes,
} from "@fortawesome/free-solid-svg-icons";
import { StaticDialog, useDialog } from "react-st-modal";
import InputMask from "react-input-mask";
import { apiUrl } from "../../../comps/apiUrl";
import { toast } from "react-toastify";

class LeaseHistoy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      add: false,
      dados: {},
      items: [],
      currentPage: 1,
      totalItems: 0,
      itemsPerPage: 100,
      edit: {},
      trash: {},
      viewProducts: {},
      comprovante: {},
      blockUser: {},
      pay: {},
      boletop: {},
    };
    this.dropdownRefs = {}; // Armazena referências para os dropdowns
  }

  componentDidMount() {
    document.title = "Histórico de locações";
    this.fetchData(this.state.currentPage);
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  toggleDropdown = (id) => {
    this.setState((prevState) => ({
      openDropdownId: prevState.openDropdownId === id ? null : id, // Fecha se clicar de novo
    }));
  };

  handleClickOutside = (event) => {
    // Verifica se o clique foi fora do dropdown aberto
    if (
      this.state.openDropdownId !== null &&
      this.dropdownRefs[this.state.openDropdownId] &&
      !this.dropdownRefs[this.state.openDropdownId].contains(event.target)
    ) {
      this.setState({ openDropdownId: null });
    }
  };

  fetchData(page) {
    if (this.state.useFilter != undefined) {
      fetch(`${apiUrl}/search-lease-history`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
        body: JSON.stringify({
          filter: this.state.filter,
          start_date: this.state.start_date,
          end_date: this.state.end_date,
          search: this.state.faturamento,
          page: page,
          limit: this.state.itemsPerPage,
        }),
      }).then((response) => {
        response.json().then((data) => {
          const totalItems = data.totalItems; // Simulado, substitua pelo valor real do backend.
          const totalPages = Math.ceil(totalItems / this.state.itemsPerPage);

          this.setState({
            items: data.items,
            currentPage: page,
            useFilter: true,
            totalPages,
            loader: false,
          });
        });
      });
    } else {
      fetch(`${apiUrl}/leasers?page=${page}&limit=${this.state.itemsPerPage}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }).then((response) => {
        if (response.status == 510) {
          localStorage.clear();
          window.location.href = "/";
          return;
        }

        response.json().then((data) => {
          const totalItems = data.totalItems; // Simulado, substitua pelo valor real do backend.
          const totalPages = Math.ceil(totalItems / this.state.itemsPerPage);

          this.setState({
            items: data.items,
            currentPage: page,
            totalPages,
            loader: false,
          });
        });
      });
    }
  }
  Payment_type(t) {
    if (t == "boletop") {
      return (
        <span class="badge bg-danger" style={{ cursor: "pointer" }}>
          <FontAwesomeIcon icon={faFlag} /> Boleto Pendente
        </span>
      );
    } else if (t == "boletoa") {
      return (
        <span class="badge bg-success" style={{ cursor: "pointer" }}>
          <FontAwesomeIcon icon={faFlag} /> Boleto Aprovado
        </span>
      );
    } else if (t == "pix") {
      return <span class="badge bg-success">Pix</span>;
    } else if (t == "boleto") {
      return <span class="badge bg-success">Boleto</span>;
    } else if (t == "cartaod") {
      return <span class="badge bg-success">Cartão de debito</span>;
    } else if (t == "cartaoc") {
      return <span class="badge bg-success">Cartão de credito</span>;
    } else if (t == "dinheiro") {
      return <span class="badge bg-success">Dinheiro</span>;
    } else if (t == "pne") {
      return (
        <span class="badge bg-danger" style={{ cursor: "pointer" }}>
          <FontAwesomeIcon icon={faFlag} /> Pagamento na Entrega
        </span>
      );
    } else if (t == "pnd") {
      return (
        <span class="badge bg-danger" style={{ cursor: "pointer" }}>
          <FontAwesomeIcon icon={faFlag} /> Pagamento na Devoluão
        </span>
      );
    }
  }
  handlePageClick = (page) => {
    this.fetchData(page);
  };
  handleNextPage = () => {
    this.fetchData(this.state.currentPage + 1);
  };
  formatarDataBR(dataISO) {
    // Converte a string ISO em um objeto Date
    const data = new Date(dataISO);

    // Extrai as partes da data
    const dia = String(data.getDate()).padStart(2, "0");
    const mes = String(data.getMonth() + 1).padStart(2, "0"); // Meses começam do zero
    const ano = data.getFullYear();

    // Extrai as partes do horário
    const horas = String(data.getHours()).padStart(2, "0");
    const minutos = String(data.getMinutes()).padStart(2, "0");

    // Retorna no formato brasileiro
    return `${dia}/${mes}/${ano} ${horas}:${minutos}`;
  }
  handlePreviousPage = () => {
    if (this.state.currentPage > 1) {
      this.fetchData(this.state.currentPage - 1);
    }
  };

  renderPagination() {
    const { currentPage, totalPages } = this.state;

    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => this.handlePageClick(i)}
          style={{
            backgroundColor: i === currentPage ? "#007bff" : "#fff",
            color: i === currentPage ? "#fff" : "#000",
            border: "1px solid #ddd",
            cursor: "pointer",
          }}
        >
          {i}
        </button>
      );
    }

    return (
      <div>
        <button
          className="btn btn-ligth"
          onClick={() => this.handlePageClick(currentPage - 1)}
          disabled={currentPage === 1}
          style={{
            cursor: currentPage === 1 ? "not-allowed" : "pointer",
          }}
        >
          &lt;
        </button>

        {pages}

        <button
          onClick={() => this.handlePageClick(currentPage + 1)}
          className="btn btn-ligth"
          disabled={currentPage === totalPages}
          style={{
            cursor: currentPage === totalPages ? "not-allowed" : "pointer",
          }}
        >
          &gt;
        </button>
      </div>
    );
  }

  handleChange = (event) => {
    const { id, value } = event.target;

    // Atualizando o estado dentro do objeto `dados`
    this.setState((prevState) => ({
      dados: {
        ...prevState.dados, // Mantém os outros campos do objeto `dados`
        [id]: value, // Atualiza apenas o campo correspondente
      },
    }));
  };

  handleEdit = (event) => {
    const { id, value } = event.target;

    // Atualizando o estado dentro do objeto `dados`
    this.setState((prevState) => ({
      edit: {
        ...prevState.edit, // Mantém os outros campos do objeto `dados`
        [id]: value, // Atualiza apenas o campo correspondente
      },
    }));
  };
  formatDate(dateString) {
    var e = dateString.split("-");
    var dateString = e[2] + "/" + e[1] + "/" + e[0];
    return dateString;
  }
  handleCpfCnpjChange = (e) => {
    function cnpj(v) {
      v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
      v = v.replace(/^(\d{2})(\d)/, "$1.$2"); //Coloca ponto entre o segundo e o terceiro dígitos
      v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3"); //Coloca ponto entre o quinto e o sexto dígitos
      v = v.replace(/\.(\d{3})(\d)/, ".$1/$2"); //Coloca uma barra entre o oitavo e o nono dígitos
      v = v.replace(/(\d{4})(\d)/, "$1-$2"); //Coloca um hífen depois do bloco de quatro dígitos
      return v;
    }

    function cpf(v) {
      v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
      v = v.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
      v = v.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
      //de novo (para o segundo bloco de números)
      v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2"); //Coloca um hífen entre o terceiro e o quarto dígitos
      return v;
    }

    if (e.target.value.length > 18) return;
    var value = e.target.value;
    if (e.target.value.length <= 14) {
      this.setState((prevState) => ({
        dados: {
          ...prevState.dados, // Mantém os outros campos do objeto `dados`
          ["doc"]: cpf(value), // Atualiza apenas o campo correspondente
        },
      }));
    } else {
      this.setState((prevState) => ({
        dados: {
          ...prevState.dados, // Mantém os outros campos do objeto `dados`
          ["doc"]: cnpj(value), // Atualiza apenas o campo correspondente
        },
      }));
    }
  };

  render() {
    if (this.state.loader) {
      return (
        <div class="area-loader">
          <div class="loader"></div>
        </div>
      );
    }
    var invoicingType = {
      1: "Diária",
      2: "Semanal",
      3: "Quizenal",
      4: "Mensal",
      5: "Anual",
    };

    var priceType = {
      1: "day_price",
      2: "weekly_price",
      3: "biweekly_price",
      4: "monthly_price",
      5: "annual_price",
    };
    return (
      <>
        <div class="content">
          <div class="card">
            <div class="card-header d-flex align-items-center justify-content-between">
              <strong>Locações</strong>
            </div>
            <div class="card-body">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (this.state.filter == 1) {
                  } else {
                    if (this.state.start_date == undefined) {
                      toast.warn("Informe a data de inicio");
                      return;
                    }

                    if (this.state.end_date == undefined) {
                      toast.warn("Informe a data de fim");
                      return;
                    }
                  }

                  if (
                    this.state.filter == 2 &&
                    this.state.faturamento == undefined
                  ) {
                    toast.warn("Selecione o metodo de pagamento");
                    return;
                  }

                  if (
                    this.state.filter == 1 &&
                    this.state.faturamento == undefined
                  ) {
                    toast.warn("Digite o nome do cliente");
                    return;
                  }
                  if (
                    this.state.filter == 1 &&
                    this.state.faturamento == undefined
                  ) {
                    toast.warn("Digite o nome do cliente");
                    return;
                  }

                  if (
                    this.state.filter == 1 &&
                    this.state.faturamento == undefined
                  ) {
                    toast.warn("Digite o nome do cliente");
                    return;
                  }
                  if (
                    this.state.filter == 1 &&
                    this.state.faturamento == undefined
                  ) {
                    toast.warn("Digite o nome do cliente");
                    return;
                  }

                  if (
                    this.state.filter == 7 &&
                    this.state.faturamento == undefined
                  ) {
                    toast.warn("Selecione o metodo de pagamento");
                    return;
                  }

                  fetch(`${apiUrl}/search-lease-history`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: localStorage.getItem("token"),
                    },
                    body: JSON.stringify({
                      filter: this.state.filter,
                      start_date: this.state.start_date,
                      end_date: this.state.end_date,
                      search: this.state.faturamento,
                    }),
                  })
                    .then((response) => {
                      response.json().then((data) => {
                        const totalItems = data.totalItems; // Simulado, substitua pelo valor real do backend.
                        const totalPages = Math.ceil(
                          totalItems / this.state.itemsPerPage
                        );

                        this.setState({
                          items: data.items,
                          currentPage: 1,
                          useFilter: true,
                          totalPages,
                          loader: false,
                        });
                      });
                    })
                    .catch((error) => {
                      toast.error(error.message);
                    });
                }}
              >
                <div class="mb-3 d-flex justify-content-center">
                  <div class="row w-50">
                    <div className="col-md-4">
                      <select
                        class="form-control"
                        onChange={(e) => {
                          this.setState({
                            filter: e.target.value,
                            faturamento: undefined,
                          });
                        }}
                      >
                        <option value="">Selecione:</option>
                        <option value="1">Cliente</option>
                        <option value="2">Faturamento</option>
                        <option value="3">Data da Locação</option>
                        <option value="4">Data de Vencimento</option>
                        <option value="6">Data de Pagamento</option>
                        <option value="8">Data de Devolução</option>
                        <option value="7">Metodo de Pagamento</option>
                      </select>
                    </div>

                    <div className="col-md-4">
                      <input
                        type="date"
                        class="form-control"
                        onChange={(e) => {
                          this.setState({
                            start_date: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="col-md-4">
                      <input
                        type="date"
                        class="form-control"
                        onChange={(e) => {
                          this.setState({
                            end_date: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div class="mb-3 d-flex justify-content-center">
                  <div class="row w-50">
                    <div className="col-md-6">
                      {this.state.filter == 7 && (
                        <>
                          <select
                            name="tpay"
                            class="form-control"
                            onChange={(e) => {
                              if (e.target.value == "todos") {
                                this.setState({
                                  filter: "1",
                                });
                              }
                              this.setState({
                                faturamento: e.target.value,
                              });
                            }}
                          >
                            <option value="">Selecione</option>

                            <option value="boletop">Boleto</option>
                            <option value="cartaoc">Cartão de Crédito</option>
                            <option value="cartaod">Cartão de Débito</option>
                            <option value="dinheiro">Dinheiro</option>
                            <option value="pix">PIX</option>
                            <option value="todos">Todos</option>
                          </select>
                        </>
                      )}

                      {this.state.filter == 1 && (
                        <>
                          <input
                            type="text"
                            class="form-control"
                            onChange={(e) => {
                              this.setState({
                                faturamento: e.target.value,
                              });
                            }}
                            placeholder="Digite:"
                          />
                        </>
                      )}

                      {this.state.filter == 2 && (
                        <>
                          <select
                            class="form-control"
                            id="faturamento"
                            name="faturamento"
                            onChange={(e) => {
                              if (e.target.value == "todos") {
                                this.setState({
                                  filter: "1",
                                });
                              }
                              this.setState({
                                faturamento: e.target.value,
                              });
                            }}
                            disabled=""
                          >
                            <option value="0">Selecione</option>
                            <option value="1">Diária</option>
                            <option value="2">Semanal</option>
                            <option value="3">Quinzenal</option>
                            <option value="4">Mensal</option>
                            <option value="5">Anual</option>
                            <option value="todos">Todos</option>
                          </select>
                        </>
                      )}
                    </div>

                    <div className="col-md-4">
                      {this.state.busca != undefined && (
                        <>
                          <button
                            className="btn btn-success"
                            onClick={() => {
                              this.fetchData(1);
                              this.setState({
                                busca: undefined,
                              });
                            }}
                          >
                            <FontAwesomeIcon icon={faRefresh} />
                          </button>
                        </>
                      )}
                    </div>
                    <div className="col-md-2">
                      <button className="btn btn-primary">
                        <FontAwesomeIcon icon={faSearch} /> Buscar
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              {this.state.items.length > 0 ? (
                <>
                  <table class="table table-striped table-bordered arvore-l">
                    <thead class="thead-dark">
                      <tr>
                        <th>ID do Cliente</th>

                        <th>Cliente</th>
                        <th>Faturamento</th>

                        <th>Valor Locação</th>
                        <th>Locação</th>
                        <th>Data de Vencimento</th>
                        <th>Data de Devolução</th>

                        <th>Data de Pagamento</th>

                        <th>Metodo de Pagamento</th>
                        <th>Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.items.map((item, index) => (
                        <>
                          <tr>
                            <td>{item.id}</td>

                            <td>
                              <Link
                                to={`/cadastro-clientes/view/${item.client_id}`}
                              >
                                {item.client_name}
                              </Link>
                            </td>
                            <td>{invoicingType[item.billing]}</td>
                            <td>
                              {item.location_value.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              })}
                            </td>

                            <td>{this.formatarDataBR(item.start_day)}</td>
                            <td>
                              {" "}
                              {item.renew.length > 0 ? (
                                <>
                                  <s>{this.formatarDataBR(item.end_day)}</s> -{" "}
                                  {this.formatarDataBR(item.renew[0].end_day)}
                                </>
                              ) : (
                                <>{this.formatarDataBR(item.end_day)}</>
                              )}{" "}
                            </td>
                            <td>
                              {item.return_day != undefined
                                ? this.formatarDataBR(item.return_day)
                                : "---"}
                            </td>

                            <td>
                              {item.payment_type == "boletop" ||
                              item.payment_type == "pnd" ||
                              item.payment_type == "pne" ? (
                                <span class="badge bg-danger">Pendente</span>
                              ) : (
                                <> {this.formatarDataBR(item.payment_a)}</>
                              )}
                            </td>

                            <td>
                              {item.payment_type == "pnd" ||
                              item.payment_type == "pne" ? (
                                <div
                                  onClick={(e) => {
                                    this.setState({ pay: item });
                                  }}
                                >
                                  {this.Payment_type(item.payment_type)}
                                </div>
                              ) : item.payment_type == "boletop" ? (
                                <div
                                  onClick={(e) => {
                                    this.setState({ boletop: item });
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <span class="badge bg-danger">
                                    Boleto Pendente
                                  </span>
                                </div>
                              ) : (
                                this.Payment_type(item.payment_type)
                              )}
                            </td>
                            <td>
                              <div
                                key={item.id}
                                ref={(el) => (this.dropdownRefs[item.id] = el)} // Salva a referência do dropdown
                                style={{
                                  position: "relative",
                                  display: "inline-block",
                                  marginRight: "10px",
                                }}
                              >
                                <button
                                  className="btn btn-primary"
                                  onClick={() => this.toggleDropdown(item.id)}
                                  style={{ padding: "10px", cursor: "pointer" }}
                                >
                                  Selecione
                                </button>

                                {this.state.openDropdownId === item.id && (
                                  <div
                                    className="dropdown-menu-rb"
                                    style={{
                                      marginLeft: -113,
                                    }}
                                  >
                                    <div className="d-flex justify-content-center align-items-center">
                                      <Link
                                        className="btn btn-primary"
                                        to={`/lease/view/${item.token}`}
                                      >
                                        <FontAwesomeIcon icon={faSearch} />
                                      </Link>
                                      <Link
                                        className="btn btn-primary"
                                        style={{
                                          marginLeft: 10,
                                          marginRight: 10,
                                        }}
                                        target="_blank"
                                        to={`/lease/contract/${item.token}`}
                                      >
                                        <FontAwesomeIcon icon={faPrint} />
                                      </Link>

                                      <Link
                                        target="_blank"
                                        className="btn btn-primary"
                                        style={{ marginRight: 10 }}
                                        to={`/lease/receipt/${item.token}`}
                                      >
                                        <FontAwesomeIcon icon={faFileAlt} />
                                      </Link>

                                      <button
                                        className="btn btn-danger"
                                        onClick={() => {
                                          this.setState({
                                            blockUser: item,
                                          });
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faUserTimes} />
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </>
              ) : (
                <>Não há resultados</>
              )}
            </div>

            <div
              className="card-footer"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {this.renderPagination()}
            </div>
          </div>
        </div>
        <StaticDialog
          isOpen={Object.keys(this.state.pay).length > 0}
          title="Informações de pagamento"
          onAfterClose={(result) => {
            this.setState({ pay: {} });
          }}
        >
          <div style={{ margin: 10 }}>
            <div>
              <label>Forma de Pagamento</label>
              <select
                id="tpay"
                class="form-control mt-2"
                onChange={(e) => {
                  this.setState({ payment_type: e.target.value });
                }}
                required=""
              >
                <option value="" disabled="" selected="">
                  Selecione
                </option>

                <option value="cartaoc">Cartão de Crédito</option>
                <option value="cartaod">Cartão de Débito</option>
                <option value="dinheiro">Dinheiro</option>
                <option value="pix">PIX</option>
              </select>
            </div>
          </div>
          <div className="modal-fotter">
            <button
              className="btn btn-primary"
              onClick={(e) => {
                if (
                  this.state.payment_type == "" ||
                  this.state.payment_type == undefined
                ) {
                  toast.warn("Por favor, selecione a forma de pagamento!");
                  return;
                }

                e.target.classList.add("load");
                var payment_type = this.state.payment_type;
                var token = this.state.pay.token;
                fetch(`${apiUrl}/lease/${this.state.pay.token}/payment`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    authorization: localStorage.getItem("token"),
                  },
                  body: JSON.stringify({
                    payment_type,
                  }),
                }).then((response) => {
                  e.target.classList.remove("load");
                  if (response.status === 200) {
                    this.setState((prevState) => ({
                      items: prevState.items.map((produto) =>
                        produto.token === token
                          ? { ...produto, payment_type, payment_a: new Date() }
                          : produto
                      ),
                      pay: {},
                    }));
                    toast.success("Pagamento efetuado com sucesso!");
                  }
                });
              }}
            >
              <FontAwesomeIcon icon={faSave} /> Salvar
            </button>
          </div>
        </StaticDialog>

        <StaticDialog
          isOpen={Object.keys(this.state.boletop).length > 0}
          title="Informações de pagamento"
          onAfterClose={(result) => {
            this.setState({ boletop: {} });
          }}
        >
          <div style={{ margin: 10 }}>
            <div>
              <label>Forma de Pagamento</label>
              <select
                id="tpay"
                class="form-control mt-2"
                onChange={(e) => {
                  this.setState({ payment_type: e.target.value });
                }}
                required=""
              >
                <option value="" disabled="" selected="">
                  Selecione
                </option>
                <option value="boletoa">Boleto Aprovado</option>
              </select>
            </div>
          </div>
          <div className="modal-fotter">
            <button
              className="btn btn-primary"
              onClick={(e) => {
                if (
                  this.state.payment_type == "" ||
                  this.state.payment_type == undefined
                ) {
                  toast.warn("Por favor, selecione a forma de pagamento!");
                  return;
                }

                e.target.classList.add("load");
                var payment_type = this.state.payment_type;
                var token = this.state.boletop.token;
                fetch(`${apiUrl}/lease/${this.state.boletop.token}/payment`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    authorization: localStorage.getItem("token"),
                  },
                  body: JSON.stringify({
                    payment_type,
                  }),
                }).then((response) => {
                  e.target.classList.remove("load");
                  if (response.status === 200) {
                    this.setState((prevState) => ({
                      items: prevState.items.map((produto) =>
                        produto.token === token
                          ? { ...produto, payment_type, payment_a: new Date() }
                          : produto
                      ),
                      boletop: {},
                    }));
                    toast.success("Pagamento efetuado com sucesso!");
                  }
                });
              }}
            >
              <FontAwesomeIcon icon={faSave} /> Salvar
            </button>
          </div>
        </StaticDialog>
        <StaticDialog
          isOpen={Object.keys(this.state.blockUser).length > 0}
          title="Bloquear Cliente"
          onAfterClose={(result) => {
            this.setState({ blockUser: {} });
          }}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              var form = e.target;

              fetch(`${apiUrl}/block-user`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  authorization: localStorage.getItem("token"),
                },
                body: JSON.stringify({
                  id: this.state.blockUser.client_id,
                  status: this.state.blockUser.client_status,
                  obs: this.state.blockUser.obs,
                }),
              }).then((response) => {
                if (response.status === 200) {
                  toast.success("Bloqueado com sucesso!");
                  this.setState({ blockUser: {} });
                }
              });
            }}
          >
            <div style={{ margin: 10 }}>
              <div className="mt-2 mb-3">
                status
                <select
                  className="form-control mt-3"
                  onChange={(e) => {
                    this.setState({
                      blockUser: {
                        ...this.state.blockUser,
                        client_status: e.target.value,
                      },
                    });
                  }}
                  value={this.state.blockUser.client_status}
                >
                  <option>Selecione:</option>
                  <option value="0">Desbloqueado</option>
                  <option value="1">Bloqueado Para Locação</option>
                  <option value="2">Bloqueado Para Serviço</option>
                  <option value="3">Bloqueio Total</option>
                </select>
              </div>
              <div className="mt-2 mb-3">
                <textarea
                  class="form-control"
                  placeholder="Observação"
                  onChange={(e) => {
                    this.setState({
                      blockUser: {
                        ...this.state.blockUser,
                        obs: e.target.value,
                      },
                    });
                  }}
                  value={this.state.blockUser.obs || ""}
                  style={{ height: "100px" }}
                />
              </div>
            </div>
            <div className="modal-fotter">
              <button type="submit" className="btn btn-primary">
                <FontAwesomeIcon icon={faSave} /> Salvar
              </button>
            </div>
          </form>
        </StaticDialog>

        <StaticDialog
          isOpen={Object.keys(this.state.comprovante).length > 0}
          title="Informações de Devolução"
          onAfterClose={(result) => {
            this.setState({ comprovante: {} });
          }}
        >
          <div className="content">
            <div class="mb-3">
              <label for="nome-cliente" class="form-label">
                Nome do Cliente
              </label>
              <input
                type="text"
                id="nome-cliente"
                class="form-control"
                value={this.state.comprovante.n_cliente}
                disabled
              />
            </div>
            <div>
              <textarea
                class="form-control"
                placeholder="Observação"
                style={{ height: "100px" }}
              />
            </div>
          </div>
          <div className="modal-fotter">
            <button className="btn btn-primary">
              <FontAwesomeIcon icon={faSave} /> Salvar
            </button>
          </div>
        </StaticDialog>
      </>
    );
  }
}

export default LeaseHistoy;
