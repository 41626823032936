import React, { Component } from "react";

import { Link, NavLink } from "react-router-dom";
import logo from "../../../img/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faChartLine,
  faClipboard,
  faClock,
  faDollar,
  faFileAlt,
  faMoneyBill,
  faPeopleArrows,
  faPeopleGroup,
  faScrewdriver,
  faScrewdriverWrench,
  faSignOut,
  faStore,
} from "@fortawesome/free-solid-svg-icons";
import { StaticDialog } from "react-st-modal";
import { jwtDecode } from "jwt-decode";
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logout: false,
      master: undefined,
      isSticky: false,
    };
  }

  getClassNames = ({ isActive }) => {
    return isActive && "active";
  };

  componentDidMount() {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);
      this.setState({ master: decoded.role });
    }
    window.addEventListener("scroll", this.handleScroll);
  }
  handleScroll = () => {
    const navbarHeight = document.querySelector(".navbar").offsetHeight;
    const navbarTop = document
      .querySelector(".navbar")
      .getBoundingClientRect().top;

    // Verifique se a navbar não está mais visível
    if (navbarTop < -navbarHeight) {
      if (!this.state.isSticky) {
        this.setState({ isSticky: true });
      }
    } else {
      if (this.state.isSticky) {
        this.setState({ isSticky: false });
      }
    }
  };
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  render() {
    const { isSticky } = this.state;
    return (
      <>
        <nav class="navbar navbar-expand-lg navbar-dark">
          <div class="container-fluid container-custom">
            <Link class="navbar-brand" to="/">
              {/* <img src={logo} alt="Logo" class="logo" /> */}
            </Link>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav ms-auto">
                <li class="nav-item">
                  {this.state.master != undefined &&
                    this.state.master == "master" && (
                      <>
                        <Link class="nav-link" to="/admin">
                          Admin
                        </Link>
                      </>
                    )}
                </li>
                {this.state.master != undefined &&
                  this.state.master == "master" && (
                    <>
                      <NavLink to="/stores" className="nav-link">
                        <FontAwesomeIcon icon={faStore} /> Lojas
                      </NavLink>
                      <NavLink to="/contracts" className="nav-link">
                        <FontAwesomeIcon icon={faFileAlt} /> Contratos
                      </NavLink>
                    </>
                  )}
                <li class="nav-item">
                  <Link
                    class="nav-link"
                    onClick={() => {
                      this.setState({ logout: true });
                    }}
                  >
                    <FontAwesomeIcon icon={faSignOut} /> Sair
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <div className={`sidebar`}>
          <div class="espaco">
            <NavLink to="/painel-adm" className={this.getClassNames}>
              <i class="fas fa-clipboard"></i>
              Painel ADM
            </NavLink>

            <NavLink to="/" className={this.getClassNames}>
              <i class="fas fa-clipboard"></i>
              Locações Vigentes
            </NavLink>

            <NavLink to="/lease-history" className={this.getClassNames}>
              <i class="fas fa-clipboard"></i>
              Locações Encerradas
            </NavLink>

            <NavLink to="/sales-history" className={this.getClassNames}>
              <i class="fas fa-clipboard"></i>
              Histórico de Vendas
            </NavLink>
            <NavLink to="equipamentos" className={this.getClassNames}>
              <FontAwesomeIcon icon={faScrewdriverWrench} /> Equipamentos
            </NavLink>
            <NavLink to="/budget" className={this.getClassNames}>
              <FontAwesomeIcon icon={faClipboard} /> Orçamento
            </NavLink>

            <NavLink to="/services" className={this.getClassNames}>
              <FontAwesomeIcon icon={faBriefcase} /> Serviços
            </NavLink>

            <NavLink to="cadastro-clientes" className={this.getClassNames}>
              <FontAwesomeIcon icon={faPeopleGroup} /> Clientes
            </NavLink>

            {this.state.master != undefined &&
              this.state.master == "master" && (
                <>
                  <NavLink to="/financeiro" className={this.getClassNames}>
                    <FontAwesomeIcon icon={faDollar} /> Financeiro
                  </NavLink>
                </>
              )}
          </div>
        </div>

        <StaticDialog
          isOpen={this.state.logout}
          title="Atenção"
          onAfterClose={(result) => {
            this.setState({ logout: false });
          }}
        >
          <div style={{ margin: 10 }}>
            Você tem certeza de que deseja encerrar a sessão? Essa ação irá
            desconectá-lo da sua conta.
          </div>
          <div className="modal-fotter">
            <button
              className="btn btn-danger"
              onClick={() => {
                window.localStorage.clear();
                window.location.href = "/";
              }}
            >
              <FontAwesomeIcon icon={faSignOut} /> Sair
            </button>
          </div>
        </StaticDialog>
      </>
    );
  }
}

export default Header;
